import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from '@/helpers/errorHandler';

const state = {
  list: [],
  returns: [],
  pending_billets: [],
  pending_payment_billets: [],
  pending_registration_billets: []
};

const getters = {
  [types.REMITTANCE_LIST]: "list",
  [types.REMITTANCE_RETURNS]: "returns",
  [types.REMITTANCE_PENDING_BILLETS]: "pending_billets",
  [types.REMITTANCE_PENDING_PAYMENT_BILLETS]: "pending_payment_billets",
  [types.REMITTANCE_PENDING_REGISTRATION_BILLETS]:
    "pending_registration_billets"
};

const mutations = {
  [types.REMITTANCE_LIST]: "list",
  [types.REMITTANCE_RETURNS]: "returns",
  [types.REMITTANCE_PENDING_BILLETS]: "pending_billets",
  [types.REMITTANCE_PENDING_PAYMENT_BILLETS]: "pending_payment_billets",
  [types.REMITTANCE_PENDING_REGISTRATION_BILLETS]:
    "pending_registration_billets"
};

const actions = {
  [types.REMITTANCE_CREATE]: async ({ dispatch }, store_id) => {
    try {
      const response = await axios.post("/banking/remittances", {
        store_id
      });
      await dispatch(types.REMITTANCE_PENDING_BILLETS, store_id);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_LIST]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(`/stores/${storeID}/remittances`);
      commit(
        types.REMITTANCE_LIST,
        response.data.data.filter(it => it.billets.length > 0)
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_UPLOAD]: async ({ dispatch }, { storeID, payload }) => {
    try {
      const response = await axios.post(`/stores/${storeID}/returns`, payload);
      await dispatch(types.REMITTANCE_PENDING_PAYMENT_BILLETS, storeID);
      await dispatch(types.REMITTANCE_PENDING_REGISTRATION_BILLETS, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_RETURNS]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(`/stores/${storeID}/returns`);
      commit(types.REMITTANCE_RETURNS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_BILLETS]: (store, { storeID, status }) => {
    try {
      return axios.get(`/stores/${storeID}/billets`, {
        params: {
          status
        }
      });
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_PENDING_BILLETS]: async ({ commit, dispatch }, storeID) => {
    try {
      const response = await dispatch(types.REMITTANCE_BILLETS, {
        storeID,
        status: "pending"
      });
      commit(types.REMITTANCE_PENDING_BILLETS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_PENDING_REGISTRATION_BILLETS]: async (
    { commit, dispatch },
    storeID
  ) => {
    try {
      const response = await dispatch(types.REMITTANCE_BILLETS, {
        storeID,
        status: "pending_registration"
      });
      commit(types.REMITTANCE_PENDING_REGISTRATION_BILLETS, response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_PENDING_PAYMENT_BILLETS]: async (
    { commit, dispatch },
    storeID
  ) => {
    try {
      const response = await dispatch(types.REMITTANCE_BILLETS, {
        storeID,
        status: "registered"
      });
      commit(types.REMITTANCE_PENDING_PAYMENT_BILLETS, response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REMITTANCE_REDO_BILLETS]: async (
    { dispatch },
    { statementID, dueDate, clientID }
  ) => {
    try {
      const payload = {
        statement_id: statementID
      };
      if (dueDate) {
        payload.due_date = dueDate;
      }
      const response = await axios.post("/banking/billets/duplicate", payload);
      await dispatch(types.CLIENT_INVOICES, clientID, {
        root: true
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
