import "@/helpers/cssPath";
import fitty from "fitty";

const fittyDirective = {
  install(Vue) {
    Vue.directive("fit", {
      inserted: (el, binding) => this._bind(el, binding)
    });
  },
  _bind(el, {
    value
  }) {
    value = value || {};

    if (!value.minSize) {
      value.minSize = 11;
    }
    if (!value.maxSize) {
      value.maxSize = 30;
    }

    // eslint-disable-next-line
    const fitties = fitty(UTILS.cssPath(el), value);
  }
};

export default fittyDirective;
