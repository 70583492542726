import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import CLIENT_STATUS from "@/content/clientStatus";
import phone from "@/helpers/phone";
import errorHandler from "@/helpers/errorHandler";

const isStatusValid = status => [4, 7, 9].indexOf(status) === -1;

const sortStatements = statements => {
  const keys = ["payments", "previous_payments", "items"];

  const result = {
    open_statement: false,
    closed_statement: false,
    future_statement: false
  };

  const sortGroup = statement => {
    const clone = {
      ...statement
    };
    keys.forEach(key => {
      clone[key] &&
        clone[key].sort((a, b) => {
          const A = new Date(a.date).getTime();
          const B = new Date(b.date).getTime();
          return A - B;
        });
    });
    return clone;
  };

  if (statements.closed_statement) {
    result.closed_statement = sortGroup(statements.closed_statement);
  }
  if (statements.open_statement) {
    result.open_statement = sortGroup(statements.open_statement);
  }
  if (statements.future_statement) {
    result.future_statement = sortGroup(statements.future_statement);
  }
  return result;
};

const state = {
  details: {},
  storeDetails: {},
  status: false,
  isSet: false,
  preserveSet: false,
  list: [],
  activities: [],
  charges: [],
  relation_activities: [],
  invoices: [],
  invoice_details: {},
  scoreNeopag: {},
  paginatedList: {
    data: [],
    page_number: 1,
    page_size: 5,
    total_entries: 0,
    total_pages: 0
  },
  installments: [],
  looseBillets: []
};

const getters = {
  [types.CLIENT_LIST]: state =>
    [...state.list].filter(it => isStatusValid(it.store_status.id)),
  [types.CLIENT_PAGINATED_LIST]: "paginatedList",
  [types.CLIENT_IS_SET]: "isSet",
  [types.CLIENT_STATUS]: "status",
  [types.CLIENT_DETAILS]: "details",
  [types.CLIENT_STORE_DETAILS]: "storeDetails",
  [types.CLIENT_INVOICES]: "invoices",
  [types.CLIENT_ACTIVITIES]: "activities",
  [types.CLIENT_ACTIVITIES_ANSWERS]: "charges",
  [types.CLIENT_INVOICE_DETAILS]: "invoice_details",
  [types.CLIENT_RELATION_ACTIVITIES]: "relation_activities",
  [types.CLIENT_PRESERVE_SET]: "preserveSet",
  [types.CLIENT_SCORE_NEOPAG]: "scoreNeopag",
  [types.CLIENT_INSTALLMENTS]: "installments",
  [types.CLIENT_LOOSE_BILLS]: "looseBillets"
};

const mutations = {
  [types.CLIENT_DETAILS]: (state, obj) => {
    if (obj && obj.user_statements) {
      obj.user_statements = sortStatements(obj.user_statements);
    }
    state.details = obj;
  },
  [types.CLIENT_PAGINATED_LIST]: "paginatedList",
  [types.CLIENT_LIST]: "list",
  [types.CLIENT_IS_SET]: "isSet",
  [types.CLIENT_STATUS]: "status",
  [types.CLIENT_STORE_DETAILS]: "storeDetails",
  [types.CLIENT_INVOICES]: "invoices",
  [types.CLIENT_INVOICE_DETAILS]: "invoice_details",
  [types.CLIENT_ACTIVITIES]: "activities",
  [types.CLIENT_ACTIVITIES_ANSWERS]: "charges",
  [types.CLIENT_RELATION_ACTIVITIES]: "relation_activities",
  [types.CLIENT_PRESERVE_SET]: "preserveSet",
  [types.CLIENT_SCORE_NEOPAG]: "scoreNeopag",
  [types.CLIENT_INSTALLMENTS]: "installments",
  [types.CLIENT_LOOSE_BILLS]: "looseBillets"
};

const actions = {
  [types.CLIENT_INSTALLMENTS]: async (
    { commit },
    { store_customer_id, start_date = null, end_date = null }
  ) => {
    try {
      const resp = await axios.get(
        `store_customer/${store_customer_id}/installments?initial_date=${start_date}&end_date=${end_date}`
      );
      commit(types.CLIENT_INSTALLMENTS, resp.data.data);
      return Promise.resolve(resp.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_SET]: ({ commit }) => {
    commit(types.CLIENT_IS_SET, true);
  },
  [types.CLIENT_PRESERVE_SET]: ({ commit }, payload) => {
    commit(types.CLIENT_PRESERVE_SET, payload);
  },
  [types.CLIENT_RESET]: ({ commit }) => {
    commit(types.CLIENT_DETAILS, false);
    commit(types.CLIENT_STATUS, false);
    commit(types.CLIENT_IS_SET, false);
  },
  [types.CLIENT]: async ({ rootState, commit }, id) => {
    try {
      if (id) {
        const response = await axios.get("/accounts/users", {
          params: {
            user_id: id,
            store_id: rootState.auth.store_id
          }
        });
        commit(types.CLIENT_DETAILS, response.data.data);
        commit(
          types.CLIENT_STATUS,
          response.data.resumed_status
            ? {
                ...CLIENT_STATUS[response.data.resumed_status.status],
                code: response.data.resumed_status.status
              }
            : false
        );
        return Promise.resolve(response);
      }
      return Promise.reject(errorHandler({}));
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_CONFIRM_PHONE]: async (_, { phone, userId }) => {
    try {
      await axios.post(
        `accounts/users/${userId}/confirm_phone_number/v2?phone_number=${phone}`,
        {}
      );
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_TOKEN]: async (ctx, { token, userID, bearer }) => {
    try {
      const response = await axios.get(
        `/accounts/users/${userID}/check_token?token=${token}`,
        {
          config: {
            headers: {
              Authorization: bearer
            }
          }
        }
      );
      return Promise.resolve(token === response.data.data.token);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_TOKEN_SALE]: async (ctx, { token, userID, customHeader }) => {
    try {
      const response = await axios.get(`/accounts/users/${userID}/get_token`, {
        params: {
          customHeader
        }
      });
      return Promise.resolve(token == response.data.data.token);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_BY_ID]: async (store, id) => {
    try {
      const response = await axios.get(`/accounts/users/${id}`);
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_ACTIVITIES]: async ({ rootState, commit }, id) => {
    try {
      const response = await axios.get(`/accounts/users/${id}/activities`, {
        params: {
          store_id: rootState.auth.store_id,
          page: 1,
          page_size: 100
        }
      });
      const activities = response.data.data.filter(it => {
        return it.type !== "notification";
      });
      const relations = response.data.data.filter(it => {
        return it.type === "notification";
      });
      commit(types.CLIENT_ACTIVITIES, activities);
      commit(types.CLIENT_RELATION_ACTIVITIES, relations);
      return response;
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_ACTIVITIES_ANSWERS]: async ({ commit }, storeCustomerID) => {
    try {
      const response = await axios.get("/charging/events", {
        params: {
          store_customer_id: storeCustomerID,
          ignore: true
        }
      });
      commit(types.CLIENT_ACTIVITIES_ANSWERS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_PUBLIC_DETAILS]: async ({ commit }, { cpf }) => {
    try {
      if (!cpf) return false;
      const cleanCPF = cpf.replace(/\D/g, "");
      if (!cleanCPF.length) return false;
      const response = await axios.get("/accounts/get_status_by", {
        params: {
          cpf: cleanCPF,
          ignore: true
        }
      });
      commit(
        types.CLIENT_DETAILS,
        response.data
          ? response.data
          : {
              cpf
            }
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_SEARCH_BY_CPF]: async ({ commit, rootState }, cpf) => {
    try {
      if (!cpf) return;
      const storeID = rootState.auth.store_id;
      const response = await axios.get(
        `/accounts/users?cpf=${cpf.replace(/\D/gi, "")}&store_id=${storeID}`
      );
      const clientData = await response.data.data;
      commit(
        types.CLIENT_DETAILS,
        clientData || {
          cpf
        }
      );

      const status = response.data.resumed_status
        ? {
            ...CLIENT_STATUS[response.data.resumed_status.status],
            code: response.data.resumed_status.status
          }
        : false;

      commit(types.CLIENT_STATUS, status);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_DETAILS]: async ({ commit }, cpf) => {
    try {
      const response = await axios.get("/accounts/users", {
        params: {
          cpf
        }
      });
      const clientData = response.data.data;

      commit(
        types.CLIENT_DETAILS,
        clientData || {
          cpf
        }
      );

      const status = response.data.resumed_status
        ? {
            ...CLIENT_STATUS[response.data.resumed_status.status],
            code: response.data.resumed_status.status
          }
        : false;

      commit(types.CLIENT_STATUS, status);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_UPDATE]: async ({ state, commit }, payload) => {
    try {
      const userID = payload.user.id || state.details.id;
      // if (payload.user && payload.user.id) delete payload.user.id;
      const resp = await axios.put(
        `/accounts/users/${userID}`,
        {
          user: payload.user
        },
        {
          params: {
            customHeader: payload.customHeader
          }
        }
      );
      commit(types.CLIENT_DETAILS, resp.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_STORE_DETAILS]: async ({ commit }, { id, storeID }) => {
    try {
      const response = await axios.get(
        `stores/${storeID}/store_customers/${id}`
      );
      commit(types.CLIENT_STORE_DETAILS, response.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_UPDATE_STORE]: async (store, { id, storePayload }) => {
    try {
      await axios.put(`/store_customer/${id}`, storePayload);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_DOCUMENTS]: async ({ commit, state }, payload) => {
    try {
      const response = await axios.post(
        `/accounts/users/${state.details.id}/documents`,
        payload
      );
      commit(types.CLIENT_DETAILS, response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_SELFIE]: async ({ commit, state }, payload) => {
    try {
      const response = await axios.post(
        `/accounts/users/${state.details.id}/selfie`,
        payload
      );
      commit(types.CLIENT_DETAILS, response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_SHARE]: ({ rootState }, { type, value: rawValue }) => {
    try {
      const value = phone.unformat(rawValue);
      const payload =
        type === "sms"
          ? {
              phone: `+55${value}`
            }
          : {
              email: value
            };
      return axios.post(
        `/stores/${rootState.auth.store_id}/send_registration_${type}`,
        payload,
        {
          ignore: true
        }
      );
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_LIST]: async ({ commit, rootState }) => {
    try {
      const response = await axios.get(
        `stores/${rootState.auth.store_id}/store_customers`
      );
      commit(types.CLIENT_LIST, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_PAGINATED_LIST]: async (
    { commit },
    {
      storeID,
      q = "",
      page = 1,
      page_size = 10,
      status = "all",
      order_by = "name_asc",
      category = "all"
    }
  ) => {
    try {
      let url = `stores/${storeID}/store_customers?order_by=${order_by}`;
      if (status !== "all") {
        url = `${url}&order_by=${order_by}`;
      }
      if (category !== "all") {
        url = `${url}&category=${category}`;
      }
      const response = await axios.get(url, {
        params: {
          q,
          page,
          page_size
        }
      });
      commit(types.CLIENT_PAGINATED_LIST, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_SEARCH]: async ({ commit }, { storeID, q }) => {
    try {
      const response = await axios.get(`stores/${storeID}/store_customers`, {
        params: {
          q,
          ignore: true
        }
      });
      commit(types.CLIENT_LIST, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_CREATE]: async ({ rootState, dispatch }, newClient) => {
    try {
      const response = await axios.post(
        `stores/${rootState.auth.store_id}/store_customers`,
        newClient
      );
      await dispatch(types.CLIENT_LIST);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_REGISTER]: async ({ rootState, dispatch }, newClient) => {
    try {
      const response = await axios.post(
        `stores/${rootState.auth.store_id}/store_customers`,
        newClient
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_INVOICES]: async ({ commit, rootState }, userID) => {
    try {
      const params = {
        store_id: rootState.auth.store_id
      };
      const response = await axios.get(`accounts/users/${userID}/statements`, {
        params
      });
      let list = [];
      if (response.data && response.data.data.length) {
        list = response.data.data.sort((a, b) => {
          const A = new Date(a.due_date).getTime();
          const B = new Date(b.due_date).getTime();
          return A - B;
        });
      }
      commit(types.CLIENT_INVOICES, list);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_INVOICE_DETAILS]: async ({ commit }, id) => {
    try {
      const response = await axios.get(`statements/${id}/detailed`);
      commit(types.CLIENT_INVOICE_DETAILS, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_UPDATE_INVOICE]: async (_, { installment_id, invoice_id }) => {
    try {
      const resp = await axios.put(`/installments/${installment_id}`, {
        installment: {
          statement_id: invoice_id
        }
      });
      return Promise.resolve(resp);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_SCORE_NEOPAG]: async (
    { commit },
    { userID, useBureau, storeId }
  ) => {
    try {
      if (!userID) return false;
      const response = await axios.get(
        `accounts/users/${userID}/score_and_level_info?use_bureau=${useBureau}&store_id=${storeId}`
      );
      commit(types.CLIENT_SCORE_NEOPAG, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_USER_DATA]: async ({ commit, rootState }, cpf) => {
    try {
      const cleanCPF = cpf.replace(/\D/g, "");
      const store_id = rootState.auth.store_id;
      if (!cleanCPF.length) return false;
      const response = await axios
        .get(`accounts/get_user_data?cpf=${cleanCPF}&store_id=${store_id}`)
        .catch(error => {
          commit(types.CLIENT_DETAILS, error.response.status);
        });
      commit(types.CLIENT_DETAILS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_CHANGE_DUE_DAY]: async (
    store,
    { storeCustomerID, payload }
  ) => {
    try {
      const response = await axios.post(
        `store_customer/${storeCustomerID}/change_due_day`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.CLIENT_LOOSE_BILLS]: async ({ commit }, { storeCustomerID }) => {
    try {
      const response = await axios.get(
        `store_customers/${storeCustomerID}/loose_billets`
      );
      commit(types.CLIENT_LOOSE_BILLS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
