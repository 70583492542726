/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  store: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M24.651 42.059c-5.078 0-9.209-4.132-9.209-9.21h3.15a6.066 6.066 0 0 0 6.059 6.06 6.066 6.066 0 0 0 6.06-6.06h3.15c0 5.078-4.132 9.21-9.21 9.21z"/><path pid="1" fill="#69737D" d="M43.029 42.059c-5.078 0-9.21-4.132-9.21-9.21h3.15c0 3.342 2.718 6.06 6.06 6.06s6.061-2.718 6.061-6.06h3.15c0 5.078-4.132 9.21-9.211 9.21z"/><path pid="2" fill="#69737D" d="M61.408 42.059c-5.078 0-9.209-4.132-9.209-9.21h3.15a6.066 6.066 0 0 0 6.059 6.06 6.066 6.066 0 0 0 6.06-6.06h3.15c0 5.078-4.132 9.21-9.21 9.21z"/><path pid="3" fill="#FF5E44" d="M38.536 63.752H24.315V49.53h14.221v14.222zm-11.07-3.15h7.92v-7.921h-7.92v7.921z"/><g><path pid="4" fill="#69737D" d="M72.454 74.294H59.642V52.705H49.129v21.589H13.783V22.912c0-6.531 5.314-11.846 11.846-11.846l34.972-.001c6.531 0 11.846 5.314 11.846 11.846l.007 51.383zm-9.662-3.15h6.512l-.008-48.231c0-4.795-3.9-8.696-8.695-8.696l-34.972.001c-4.795 0-8.695 3.9-8.695 8.695v48.231h29.045V49.555h16.813v21.589z"/></g><g><path pid="5" fill="#69737D" d="M15.221 29.803h55.707v3.15H15.221z"/></g>`
  }
});
