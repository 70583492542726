import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  plans: [],
  details: {},
  payment_methods: [],
  config: {
    clients_number: 2,
    iugu_activated: false,
    price: 0,
    allow_billets: false,
    multiple_stores: false
  }
};

const getters = {
  [types.SUBSCRIPTION_PLANS]: "plans",
  [types.SUBSCRIPTION_CONFIG]: "config",
  [types.SUBSCRIPTION_DETAILS]: "details",
  [types.SUBSCRIPTION_PAYMENT_METHODS]: "payment_methods"
};

const mutations = {
  [types.SUBSCRIPTION_PLANS]: "plans",
  [types.SUBSCRIPTION_CONFIG]: "config",
  [types.SUBSCRIPTION_DETAILS]: "details",
  [types.SUBSCRIPTION_PAYMENT_METHODS]: "payment_methods"
};

const actions = {
  [types.SUBSCRIPTION_PLANS]: async ({ commit }) => {
    const response = await axios.get("/billing/subscription_plans");
    const list = response.data.data.sort((a, b) => {
      const A = +a.price;
      const B = +b.price;
      return A - B;
    });
    commit(types.SUBSCRIPTION_PLANS, list);
    return response;
  },
  [types.SUBSCRIPTION_DETAILS]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(
        `/billing/subscriptions?store_id=${storeID}`
      );
      const currentSub = response.data.data.pop() || [];
      const config = {
        clients_number: currentSub.plan ? currentSub.plan.clients_number : 2,
        price: currentSub.plan.price,
        iugu_activated: currentSub.iugu_activated,
        allow_billets: currentSub.plan.create_billets,
        multiple_stores: currentSub.plan && currentSub.plan.multiple_stores
      };

      commit(types.SUBSCRIPTION_CONFIG, config);
      commit(types.SUBSCRIPTION_DETAILS, currentSub);
      return Promise.resolve(currentSub);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SUBSCRIPTION_PAYMENT_METHODS]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(
        `/billing/payment_methods?store_id=${storeID}`
      );
      commit(types.SUBSCRIPTION_PAYMENT_METHODS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SUBSCRIPTION_PAYMENT_METHODS_CREATE]: async (store, payload) => {
    try {
      const response = await axios.post("/billing/payment_methods", payload);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SUBSCRIPTION_PAYMENT_METHODS_UPDATE]: async (
    store,
    { storeID, default_payment_method_id }
  ) => {
    try {
      const response = await axios.put(`billing/clients/${storeID}`, {
        default_payment_method_id
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.GET_SUBSCRIPTION_PAYMENT_METHODS]: async (store, { storeID }) => {
    try {
      const response = await axios.get(`billing/clients/${storeID}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SUBSCRIPTION_CREATE]: async (
    store,
    { storeID, planID, iuguID, start }
  ) => {
    try {
      const response = await axios.post("/billing/subscriptions", {
        store_id: storeID,
        subscription_plan_id: planID,
        iugu_subscription_identifier: iuguID,
        start
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SUBSCRIPTION_UPDATE]: async (
    store,
    { storeID, planID, installments, paymentId, promotionalCode = "" }
  ) => {
    try {
      const response = await axios.put(`/billing/subscriptions/${storeID}`, {
        subscription_plan_id: planID,
        installments,
        payment_method_id: paymentId,
        promotional_code: promotionalCode
      });
      return response;
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
