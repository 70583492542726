/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  filter: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M64.039 25.818H21.707a4.914 4.914 0 0 1-4.909-4.909 4.914 4.914 0 0 1 4.909-4.908h42.332a4.914 4.914 0 0 1 4.909 4.908 4.914 4.914 0 0 1-4.909 4.909zm-42.332-6.667c-.97 0-1.759.788-1.759 1.758s.789 1.759 1.759 1.759h42.332c.97 0 1.759-.789 1.759-1.759s-.789-1.758-1.759-1.758H21.707z"/><path pid="1" fill="#69737D" d="M34.312 70.077V47.658L20.216 25.041l2.674-1.666 14.572 23.383v17.311l10.808-7.475-.001-9.794 14.572-23.383 2.674 1.666L51.419 47.7l-.04 10.572z"/><path pid="2" fill="#FF5E44" d="M32.39 28.375l6.47 10.38-2.674 1.667-6.469-10.38z"/>`
  }
});
