/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  back: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M16.787 37.61h61.765v9.786H16.787z"/><path pid="1" fill="#69737D" d="M6.488 42.495l31.901-31.14 7.086 6.924-24.808 24.216 24.808 24.229-7.092 6.92z"/>`
  }
});
