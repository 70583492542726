import Vue from "vue";
import Vuex from "vuex";
import * as modules from "@/store/modules";
import appState from "vuex-persistedstate";
import versionState from "vuex-persistedstate";

import {
  name,
  version
} from "../../package";

Vue.use(Vuex);

const appKey = `${name}`;
const versionKey = `${name}@${version}`;

const store = new Vuex.Store({
  strict: true,
  modules,
  plugins: [
    versionState({
      key: versionKey,
      paths: [
        "auth",
        "auth.token",
        "auth.store_id",
        "auth.store_details"
      ]
    }),
    appState({
      key: appKey,
      paths: [
        "store.ignore_list",
        "store.customers_to_analyze",
        "auth.branch_choice"
      ]
    })
  ]
});

export default store;
