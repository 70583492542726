import * as types from "@/store/types";
import shortly from "vuex-shortly";

const state = {
  open: false,
  items: [{
    name: "Sale",
    path: "/sale"
  }]
};

const getters = {
  [types.MENU_OPEN]: "open",
  [types.MENU_ITEMS]: "items"
};

const mutations = {
  [types.MENU_OPEN]: "open"
};

const actions = {
  [types.MENU_OPEN]: ({
    commit
  }) => commit(types.MENU_OPEN, true),
  [types.MENU_CLOSE]: ({
    commit
  }) => commit(types.MENU_OPEN, false)
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
