import * as types from "@/store/types";

import moment from "moment";
import store from "@/store";

moment.locale("pt-BR");

const Store = () =>
  import(/* webpackChunkName: 'store' */ "@/views/StoreNeopag");
const Login = () =>
  import(/* webpackChunkName: 'login' */ "@/views/LoginNeopag");
const SignUp = () =>
  import(/*webpackChunkName: 'signup' */ "@/views/SignUpNeopag");
const P404 = () => import(/* webpackChunkName: '404' */ "@/views/404");
const Dashboard = () =>
  import(/* webpackChunkName: 'dashboard' */ "@/views/DashboardNeopag");

// Sale group
const Sale = () => import(/* webpackChunkName: 'sale' */ "@/views/SaleNeopag");
const SaleDetails = () =>
  import(/* webpackChunkName: 'sale' */ "@/containers/Sale/Details");
const SaleSummary = () =>
  import(/* webpackChunkName: 'sale' */ "@/containers/Sale/Summary");
const SaleToken = () =>
  import(/* webpackChunkName: 'sale' */ "@/containers/Sale/Token");
const SaleReceipt = () =>
  import(/* webpackChunkName: 'sale' */ "@/containers/Sale/Receipt");

// Payment group
const Payment = () =>
  import(/* webpackChunkName: 'payment' */ "@/views/Payment/PaymentNeopag");
const PaymentDetails = () =>
  import(/* webpackChunkName: 'payment' */ "@/containers/Payment/Details");
const PaymentSummary = () =>
  import(/* webpackChunkName: 'payment' */ "@/containers/Payment/Summary");
const PaymentReceipt = () =>
  import(/* webpackChunkName: 'payment' */ "@/containers/Payment/Receipt");

// PaymentBillet group
const PaymentBillet = () =>
  import(
    /* webpackChunkName: 'payment-billet' */ "@/views/Payment/PaymentBilletNeopag"
  );
const PaymentBilletsDetails = () =>
  import(
    /* webpackChunkName: 'payment-billet' */ "@/containers/PaymentBillets/Details"
  );
const PaymentBilletSummary = () =>
  import(
    /* webpackChunkName: 'payment' */ "@/containers/PaymentBillets/Summary"
  );

// Deal group
const Deal = () => import(/* webpackChunkName: 'deal' */ "@/views/DealNeopag");
const DealDetails = () =>
  import(/* webpackChunkName: 'deal' */ "@/containers/Deal/Details");
const DealSummary = () =>
  import(/* webpackChunkName: 'deal' */ "@/containers/Deal/Summary");
const DealReceipt = () =>
  import(/* webpackChunkName: 'deal' */ "@/containers/Deal/Receipt");

const Register = () =>
    import(/* webpackChunkName: 'deal' */ "@/views/RegisterUser");
const RegisterUser = () =>
  import(/* webpackChunkName: 'deal' */ "@/containers/Clientes/CadastrarClientes");

const Charge = () =>
  import(/* webpackChunkName: 'charge' */ "@/views/Charge/ChargeNeopag");
const ChargeAction = () =>
  import(/* webpackChunkName: 'charge' */ "@/views/Charge/ChargeActions");
const ChargeReports = () =>
  import(/* webpackChunkName: 'charge' */ "@/views/Charge/ChargeReports");

// Inquiry group
const Inquiry = () =>
  import(/* webpackChunkName: 'inquiry' */ "@/views/InquiryNeopag");
const InquiryCPF = () =>
  import(/* webpackChunkName: 'inquiry' */ "@/containers/Inquiry/CPF");
const InquirySuitability = () =>
  import(
    /* webpackChunkName: 'inquiry' */ "@/containers/SignUpCustomer/SignUpCustomer"
  );
const InquiryScore = () =>
  import(/* webpackChunkName: 'inquiry' */ "@/containers/Inquiry/Score");
const DocumentsPhotos = () =>
  import(/* webpackChunkName: 'inquiry' */ "@/containers/Register/Documents");

// Customer group
const Customer = () =>
  import(/* webpackChunkName: 'customer' */ "@/views/ClientProfile");
const ClientProfileHistory = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileHistory"
  );
const ClientProfileCreditHistory = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileCreditHistory"
  );
const ClientProfileInfo = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileInfo"
  );
const ClientProfileStatements = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileStatements"
  );
// const ClientProfileInstallments = () =>
//   import(
//     /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileInstallments"
//   );
const ClientProfileDueStatements = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileDueStatements"
  );
const ClientProfileNotifications = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileNotifications"
  );
const ClientProfileCharges = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileCharges"
  );
const ClientProfileBills = () =>
  import(
    /*webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileBills"
  );
const ClientProfileLooseBills = () =>
  import(
    /*webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileLooseBills"
  );
const ClientProfileSettings = () =>
  import(
    /* webpackChunkName: 'customer' */ "@/containers/Client/ClientProfileSettings"
  );

// Customers group
const Customers = () =>
  import(/* webpackChunkName: 'customers' */ "@/views/CustomersNeopag");
const CustomersList = () =>
  import(/* webpackChunkName: 'customers' */ "@/containers/Customers/List");
const CustomersRequests = () =>
  import(/* webpackChunkName: 'customers' */ "@/containers/Customers/Requests");
const CustomerReceipt = () =>
  import(/* webpackChunkName: 'customers' */ "@/containers/Receipt/Receipt");
const CustomersReports = () =>
  import(/* webpackChunkName: 'customers' */ "@/views/CustomersReportsNeopag");

// Reports group
const Reports = () =>
  import(/* webpackChunkName: 'reports' */ "@/views/ReportsNeopag");
const SalesReportsNeopag = () =>
  import(
    /* webpackChunkName: 'reports' */ "@/views/Reports/SalesReportsNeopag"
  );
const PaymentsReportsNeopag = () =>
  import(
    /* webpackChunkName: 'reports' */ "@/views/Reports/PaymentsReportsNeopag"
  );
const DealsReportsNeopag = () =>
  import(
    /* webpackChunkName: 'reports' */ "@/views/Reports/DealsReportsNeopag"
  );
const StatementsReportsNeopag = () =>
  import(
    /* webpackChunkName: 'reports' */ "@/views/Reports/StatementsReportsNeopag"
  );
const BilletsReportsNeopag = () =>
  import(
    /* webpackChunkName: 'reports' */ "@/views/Reports/BilletsReportsNeopag"
  );
const DynamicReportsNeopag = () =>
  import(
    /* webpackChunkName: 'reports' */ "@/views/Reports/DynamicReportsNeopag"
  );

// Settings group
const Settings = () =>
  import(/* webpackChunkName: 'settings' */ "@/views/SettingsNeopag");
const SettingsCompany = () =>
  import(/* webpackChunkName: 'settings' */ "@/containers/Settings/Company");
const Marketing = () =>
  import(/* webpackChunkName: 'settings' */ "@/containers/Settings/Marketing");
const ResetPassword = () =>
  import(
    /* webpackChunkName: 'settings' */ "@/containers/Settings/ResetPassword"
  );
const SettingsSubscription = () =>
  import(
    /* webpackChunkName: 'settings' */ "@/containers/Settings/Subscription"
  );
const SubscriptionSummary = () =>
  import(
    /* webpackChunkName: 'settings' */ "@/containers/Subscription/Summary"
  );
// const SubscriptionOptions = () =>
//   import( /* webpackChunkName: 'settings' */ "@/containers/Subscription/Options");
const SubscriptionPayment = () =>
  import(
    /* webpackChunkName: 'settings' */ "@/containers/Subscription/Payment"
  );
const SettingsPlans = () =>
  import(/* webpackChunkName: 'settings' */ "@/containers/Settings/Plans");
const SettingsStaff = () =>
  import(/* webpackChunkName: 'settings' */ "@/containers/Settings/Staff");
const SettingsBranches = () =>
  import(/* webpackChunkName: 'settings' */ "@/containers/Settings/Branches");
const SettingsBillets = () =>
  import(/* webpackChunkName: 'settings' */ "@/containers/Settings/Billets");
const SettingsRemittance = () =>
  import(/* webpackChunkName: 'settings' */ "@/views/RemittanceNeopag");

const Card = () => import(/* webpackChunkName: 'card' */ "@/views/CardNeopag");

const Help = () => import(/* webpackChunkName: 'help' */ "@/views/HelpNeopag");

const SubscriptionOverview = () =>
  import(
    /* webpackChunkName: 'subscriptionOverview' */ "@/views/SubscriptionOverview"
  );

const SignUpSummary = () =>
  import(
    /* webpackChunkName: 'signUpSummary' */ "@/containers/Register/Details"
  );
// Promotion group
const Promotions = () =>
  import(/* webpackChunkName: 'promotions' */ "@/views/PromotionsNeopag");

export default [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: async (to, from, next) => {
      // const fromSignUpStep = store.getters[types.AUTH_CAMES_FROM_SIGNUP];
      if (from.path === "/signup") {
        next();
      } else {
        store.dispatch(types.AUTH_LOGOUT);
        next();
      }
    }
  },
  {
    path: "/signup",
    name: "Cadastrar Loja",
    component: SignUp,
    beforeEnter: (to, from, next) => {
      store.dispatch(types.AUTH_LOGOUT);
      next();
    }
  },
  {
    beforeEnter: async (to, from, next) => {
      const { origin, token, storeId, branchId, userId } = to.query;
      if (origin === "app") {
        await store.dispatch(types.AUTH_ORIGIN, "app");
        await store.dispatch(types.AUTH_TOKEN, `Bearer ${token}`);
        await store.dispatch(types.AUTH_ACTIVE_BRANCH, branchId);
        await store.dispatch(types.AUTH_ACTIVE_STORE, { storeID: storeId });
        await store.dispatch(types.SUBSCRIPTION_DETAILS, storeId);
        const userData = await store.dispatch(types.CLIENT, userId);
        await store.dispatch(types.AUTH_USER, userData.data.data);
      }
      next();
    },
    path: "/assinar-plano",
    name: "Planos - Visão geral",
    component: SubscriptionOverview
  },
  {
    path: "/cadastro-cliente",
    name: "Clientes - cadastro",
    component: SignUpSummary
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: Store,
    beforeEnter: async (to, from, next) => {
      const { origin, token, branchId, userId } = to.query;
      if (origin === "app") {
        const permissionsString = to.query.permissions.split(",");
        let _permissions = {};
        permissionsString.map(permission => {
          const splittedPermission = permission.split("-");
          _permissions = {
            ..._permissions,
            [splittedPermission[0]]: splittedPermission[1] === "true"
          };
        });
        await store.dispatch(types.AUTH_ORIGIN, "app");
        await store.dispatch(types.AUTH_TOKEN, `Bearer ${token}`);
        await store.dispatch(types.AUTH_ACTIVE_BRANCH, branchId);
        await store.dispatch(types.AUTH_ACTIVE_STORE, {
          storeID: to.query.storeId
        });
        await store.dispatch(types.SUBSCRIPTION_DETAILS, to.query.storeId);
        const storeData = await store.dispatch(
          types.STORE_INFO,
          to.query.storeId
        );
        const userData = await store.dispatch(types.CLIENT, userId);
        await store.dispatch(types.AUTH_USER, userData.data.data);
        await store.dispatch(types.AUTH_ACTIVE_STORE_DETAILS, {
          ...storeData,
          permissions: _permissions
        });
      }
      const isAuth = await store.getters[types.AUTH];
      const storeId = await store.getters[types.AUTH_ACTIVE_STORE];
      const { permissions } = await store.getters[
        types.AUTH_ACTIVE_STORE_DETAILS
      ];
      if (!isAuth || !storeId || !permissions) {
        return next({
          name: "Login",
          params: {
            redirect: to.path
          }
        });
      } else if (storeId) {
        const trialStatus = await store.dispatch(
          types.STORE_TRIAL_STATUS,
          storeId
        );
        if (trialStatus && trialStatus.days_remaining <= 0) {
          return next({
            name: "Planos - Visão geral"
          });
        }
      }
      next();
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: async (to, from, next) => {
          const { permissions } = await store.getters[
            types.AUTH_ACTIVE_STORE_DETAILS
          ];
          if (permissions && !permissions.dashboard) {
            next("*");
          } else {
            next();
          }
        }
      },
      {
        path: "/venda",
        component: Sale,
        name: "Venda",
        props: true,
        beforeEnter: async (to, from, next) => {
          const { permissions } = await store.getters[
            types.AUTH_ACTIVE_STORE_DETAILS
          ];
          const clientDetails = await store.getters[types.CLIENT_DETAILS];
          const storeInfo = await store.getters[types.STORE_INFO];
          if (!permissions.sales) {
            return next("*");
          }
          if (
            from.fullPath.includes("/recebimento") ||
            from.fullPath.includes("/recebimento/detalhes") ||
            from.fullPath.includes("/recebimento-boleto") ||
            from.fullPath.includes("/recebimento-boleto/detalhes")
          ) {
            await store.dispatch(types.CLIENT_RESET);
          }
          await store.dispatch(types.SALE_RESET);
          const preserveSet = store.getters[types.CLIENT_PRESERVE_SET];
          if (preserveSet) {
            store.dispatch(types.CLIENT_PRESERVE_SET, false);
            return next();
          }
          if (!to.params.isClient) {
            store.dispatch(types.CLIENT_RESET);
          } else {
            await store.dispatch(types.CLIENT_SET);
          }
          if (clientDetails && clientDetails.store_status) {
            if (storeInfo.legacy_mode) {
              if ([1, 5, 10, 11].indexOf(clientDetails.store_status.id) < 0) {
                await store.dispatch(types.CLIENT_RESET);
              }
            }
          }
          return next();
        },
        children: [
          {
            path: "",
            name: "Detalhes da venda",
            meta: {
              params: {
                step: 0,
                type: "sale"
              }
            },
            component: SaleDetails
          },
          {
            path: "resumo",
            name: "Sumario da venda",
            meta: {
              params: {
                step: 1,
                type: "sale"
              }
            },
            component: SaleSummary,
            beforeEnter: async (to, from, next) => {
              const saleDetails = await store.getters[types.SALE_DETAILS];
              const clientDetails = await store.getters[types.CLIENT_DETAILS];
              if (saleDetails.valid) {
                await store.dispatch(types.SALE_INSTALLMENTS, {
                  plan_id: saleDetails.plan.id,
                  sale: {
                    amount: saleDetails.amount,
                    down_payments: saleDetails.downPayment,
                    installments_number: saleDetails.installments,
                    first_due_date: moment(
                      saleDetails.first_due_date,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD"),
                    sale_date: moment().format("YYYY-MM-DD")
                  },
                  store_customer_id: clientDetails.store_customer_id
                });
                next();
                return;
              }
              next({
                name: "Detalhes da venda"
              });
            }
          },
          {
            path: "token",
            name: "Token da venda",
            meta: {
              params: {
                step: 3,
                type: "sale"
              }
            },
            component: SaleToken,
            beforeEnter: async (to, from, next) => {
              const saleDetails = await store.getters[types.SALE_DETAILS];
              if (saleDetails.valid) {
                next();
                return;
              }
              next({
                name: "Detalhes da venda"
              });
            }
          },
          {
            path: "recibo",
            name: "Recibo da venda",
            meta: {
              params: {
                step: 4,
                type: "sale"
              }
            },
            component: SaleReceipt,
            beforeEnter: (to, from, next) => {
              if (
                from.name === "Token da venda" ||
                from.name === "Sumario da venda" ||
                from.name === "Imprimir recibo da venda"
              ) {
                next();
                return;
              }
              next({
                name: "Detalhes da venda"
              });
            }
          },
          {
            path: "recibo-impressao",
            name: "Imprimir recibo da venda",
            meta: {
              params: {
                step: 5,
                type: "sale"
              }
            },
            component: () =>
              import(/* webpackChunkName: 'Sale' */ "@/components/Sale/Receipt")
          }
        ]
      },
      {
        path: "/recebimento",
        name: "Recebimento",
        component: Payment,
        props: true,
        beforeEnter: async (to, from, next) => {
          const { permissions } = await store.getters[
            types.AUTH_ACTIVE_STORE_DETAILS
          ];
          if (!permissions.payments) {
            return next("*");
          }
          if (
            from.fullPath.includes("/recebimento-boleto") ||
            from.fullPath.includes("/recebimento-boleto/detalhes")
          ) {
            await store.dispatch(types.CLIENT_RESET);
            await store.dispatch(types.PAYMENT_RESET);
            return next();
          }
          const preserveSet = store.getters[types.CLIENT_PRESERVE_SET];
          if (preserveSet) {
            await store.dispatch(types.CLIENT_PRESERVE_SET, false);
            return next();
          }
          if (!to.params.isClient) {
            await store.dispatch(types.CLIENT_RESET);
          } else {
            await store.dispatch(types.CLIENT_SET);
          }
          next();
        },
        children: [
          {
            path: "detalhes",
            name: "Detalhes do recebimento",
            meta: {
              params: {
                step: 0,
                type: "payment"
              }
            },
            component: PaymentDetails
          },
          {
            path: "resumo",
            name: "Sumario do recebimento",
            meta: {
              params: {
                step: 1,
                type: "payment"
              }
            },
            component: PaymentSummary,
            beforeEnter: async (to, from, next) => {
              const isPaymentValid = await store.getters[types.PAYMENT_DETAILS]
                .is_valid;
              if (!isPaymentValid) {
                next({
                  name: "Detalhes do recebimento"
                });
                return;
              }
              next();
            }
          },
          {
            path: "recibo",
            name: "Recibo do recebimento",
            meta: {
              params: {
                step: 2,
                type: "payment"
              }
            },
            component: PaymentReceipt,
            beforeEnter: async (to, from, next) => {
              if (
                from.name === "Sumario do recebimento" ||
                from.name === "Imprimir recibo do recebimento"
              ) {
                next();
                return;
              }
              next({
                name: "Detalhes do recebimento"
              });
            }
          },
          {
            path: "recibo-impressao",
            name: "Imprimir recibo do recebimento",
            meta: {
              params: {
                step: 3,
                type: "payment"
              }
            },
            component: () =>
              import(
                /* webpackChunkName: 'Payment' */ "@/components/Payment/Receipt"
              )
          }
        ]
      },
      {
        path: "/recebimento-boleto",
        name: "Recebimento Boleto",
        component: PaymentBillet,
        props: true,
        beforeEnter: async (to, from, next) => {
          const { permissions } = await store.getters[
            types.AUTH_ACTIVE_STORE_DETAILS
          ];
          if (!permissions.payments) {
            return next("*");
          }
          if (
            from.fullPath.includes("/recebimento") ||
            from.fullPath.includes("/recebimento/detalhes")
          ) {
            await store.dispatch(types.CLIENT_RESET);
          }
          const preserveSet = store.getters[types.CLIENT_PRESERVE_SET];
          if (preserveSet) {
            store.dispatch(types.CLIENT_PRESERVE_SET, false);
            next();
            return;
          }
          next();
        },
        children: [
          {
            path: "detalhes",
            name: "Detalhes do recebimento",
            meta: {
              params: {
                step: 0,
                type: "payment-billet"
              }
            },
            component: PaymentBilletsDetails
          },
          {
            path: "resumo",
            name: "Sumario do recebimento por boleto",
            meta: {
              params: {
                step: 1,
                type: "payment-billet"
              }
            },
            component: PaymentBilletSummary,
            beforeEnter: async (to, from, next) => {
              if (from.fullPath != "/recebimento-boleto/detalhes") {
                next({
                  name: "Detalhes do recebimento por boleto"
                });
              }
              next();
            }
          }
        ]
      },
      {
        path: "/acordos-e-reparcelamento",
        component: Deal,
        beforeEnter: async (to, from, next) => {
          const { permissions } = await store.getters[
            types.AUTH_ACTIVE_STORE_DETAILS
          ];
          if (!permissions.deals) {
            return next("*");
          }
          store.dispatch(types.DEAL_RESET);
          store.dispatch(types.DEAL_PLANS);
          if (from.fullPath.indexOf("/perfil") === -1) {
            await store.dispatch(types.CLIENT_RESET);
          } else {
            await store.dispatch(types.CLIENT_SET);
          }
          next();
        },
        children: [
          {
            path: "",
            name: "Detalhes do reparcelamento",
            meta: {
              params: {
                step: 0,
                type: "deal"
              }
            },
            component: DealDetails
          },
          {
            path: "resumo",
            name: "Sumario do reparcelamento",
            meta: {
              params: {
                step: 1,
                type: "deal"
              }
            },
            component: DealSummary,
            beforeEnter: async (to, from, next) => {
              const dealDetails = await store.getters[types.DEAL_DETAILS];
              const client = await store.getters[types.CLIENT_DETAILS];
              if (!dealDetails.valid) {
                next({
                  name: "Detalhes do reparcelamento"
                });
              }
              try {
                await store.dispatch(types.DEAL_INSTALLMENTS, {
                  sale: {
                    amount:
                      dealDetails.amount -
                      dealDetails.discount -
                      dealDetails.down_payment,
                    installments_number: dealDetails.installments,
                    sale_date: moment().format("YYYY-MM-DD")
                  },
                  first_due_date: moment(
                    dealDetails.first_due_date,
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD"),
                  plan_id: dealDetails.plan.id,
                  store_customer_id: client.store_customer_id
                });
                next();
              } catch (error) {
                next({
                  name: "Detalhes do reparcelamento"
                });
              }
            }
          },
          {
            path: "recibo",
            name: "Recibo do reparcelamento",
            meta: {
              params: {
                step: 2,
                type: "deal"
              }
            },
            component: DealReceipt,
            beforeEnter: async (to, from, next) => {
              if (
                from.name === "Sumario do reparcelamento" ||
                from.name === "Imprimir recibo do reparcelamento"
              ) {
                next();
                return;
              }
              next({
                name: "Detalhes do reparcelamento"
              });
            }
          },
          {
            path: "recibo-impressao",
            name: "Imprimir recibo do reparcelamento",
            meta: {
              params: {
                step: 3,
                type: "deal"
              }
            },
            component: () =>
              import(/* webpackChunkName: 'Deal' */ "@/components/Deal/Receipt")
          }
        ]
      },
      {
        path: "/esteira-de-cobranca",
        name: "Esteira de cobrança",
        component: Charge,
        meta: {
          params: {
            chargeType: "painel"
          }
        },
        beforeEnter: async (to, from, next) => {
          const { permissions } = await store.getters[
            types.AUTH_ACTIVE_STORE_DETAILS
          ];
          if (!permissions.charging) {
            return next("*");
          }
          next();
        }
      },
      {
        path: "/esteira-de-cobranca-acoes",
        name: "Ações esteira de cobrança",
        component: ChargeAction,
        meta: {
          params: {
            chargeType: "acoes"
          }
        }
      },
      {
        path: "/esteira-de-cobranca-relatorio",
        name: "Clientes Pendentes",
        component: ChargeReports,
        meta: {
          params: {
            chargeType: "relatorio",
            statusSeries: ""
          }
        }
      },
      {
        path: "/cadastrar-cliente",
        component: Register,
        children: [
          {
            path: "",
            name: "Cadastro de Cliente",
            component: RegisterUser,
            beforeEnter: async (to, from, next) => {
              await store.dispatch(types.CLIENT_RESET);
              next();
            }
          }
        ]
      },
      {
        path: "/consulta-neopag",
        component: Inquiry,
        children: [
          {
            path: "",
            name: "Consulta Neopag - CPF",
            component: InquiryCPF,
            beforeEnter: async (to, from, next) => {
              await store.dispatch(types.CLIENT_RESET);
              next();
            }
          },
          {
            path: "suitability",
            name: "Consulta Neopag - Suitability",
            component: InquirySuitability,
            beforeEnter: async (to, from, next) => {
              await store.dispatch(types.SUITABILITY_QUESTIONS);
              if (!store.getters[types.CLIENT_IS_SET]) {
                next({
                  name: "Consulta Neopag - CPF"
                });
              }
              next();
            }
          },
          {
            path: "/suitability/documents",
            name: "Consulta Neopag - Documentos",
            component: DocumentsPhotos,
            beforeEnter: async (to, from, next) => {
              await store.dispatch(types.SUITABILITY_QUESTIONS);
              if (!store.getters[types.CLIENT_IS_SET]) {
                next({
                  name: "Consulta Neopag - CPF"
                });
              }
              next();
            }
          },
          {
            path: "score",
            name: "Consulta Neopag - Score",
            component: InquiryScore,
            beforeEnter: (to, from, next) => {
              const c1 =
                from.name === "Consulta Neopag - Suitability" ||
                from.name === "Aprovar clientes" ||
                from.name === "Consulta Neopag - Documentos";
              const c2 = store.getters[types.CLIENT_IS_SET];
              if (!c1 || !c2) {
                next({
                  name: "Consulta Neopag - CPF"
                });
              }
              next();
            }
          }
        ]
      },
      {
        name: "Clientes",
        path: "/clientes",
        component: Customers,
        children: [
          {
            path: "",
            name: "Clientes",
            component: CustomersList
          },
          {
            path: "clientes-neopag",
            name: "Aprovar clientes",
            component: CustomersRequests
          }
        ]
      },
      {
        name: "Arquivos de remessa e retorno",
        path: "remessa-e-retorno",
        component: SettingsRemittance
      },
      {
        name: "Cliente",
        path: "/perfil/:clientID",
        component: Customer,
        meta: {
          params: {
            activeMenu: ""
          }
        },
        children: [
          {
            name: "Perfil - Histórico de lançamentos",
            path: "historico-lancamentos",
            component: ClientProfileHistory,
            meta: {
              params: {
                activeMenu: "history"
              }
            }
          },
          {
            name: "Perfil - Histórico de consultas",
            path: "historico-consultas",
            component: ClientProfileCreditHistory,
            meta: {
              params: {
                activeMenu: "creditHistory"
              }
            }
          },
          {
            name: "Perfil - Dados Pessoais",
            path: "dados-pessoais",
            component: ClientProfileInfo,
            meta: {
              params: {
                activeMenu: "info"
              }
            }
          },
          {
            name: "Perfil - Faturas",
            path: "faturas",
            component: ClientProfileStatements,
            meta: {
              params: {
                activeMenu: "statements"
              }
            }
          },
          // {
          //   name: "Perfil - Parcelas",
          //   path: "parcelas",
          //   component: ClientProfileInstallments,
          //   meta: {
          //     params: {
          //       activeMenu: "installments"
          //     }
          //   }
          // },
          {
            name: "Perfil - Compras a vencer",
            path: "compras-a-vencer",
            component: ClientProfileDueStatements,
            meta: {
              params: {
                activeMenu: "dueStatements"
              }
            }
          },
          {
            name: "Perfil - Notificações",
            path: "notificações",
            component: ClientProfileNotifications,
            meta: {
              params: {
                activeMenu: "notifications"
              }
            }
          },
          {
            name: "Perfil - Cobranças",
            path: "cobrancas",
            component: ClientProfileCharges,
            meta: {
              params: {
                activeMenu: "charges"
              }
            }
          },
          {
            name: "Perfil - Boletos de recebimento",
            path: "boletos-recebimento",
            component: ClientProfileBills,
            meta: {
              params: {
                activeMenu: "billets"
              }
            }
          },
          {
            name: "Perfil - Boletos avulsos",
            path: "boletos-avulsos",
            component: ClientProfileLooseBills,
            meta: {
              params: {
                activeMenu: "loose-billets"
              }
            }
          },
          {
            name: "Perfil - Configurações",
            path: "configuracoes",
            component: ClientProfileSettings,
            meta: {
              params: {
                activeMenu: "settings"
              }
            }
          }
        ]
      },
      {
        name: "Recibo do Cliente",
        path: "/perfil/:clientID/receipt",
        component: CustomerReceipt,
        beforeEnter: async (to, from, next) => {
          const authOrigin = await store.getters[types.AUTH_ORIGIN];
          if (authOrigin === "app") {
            const { action, id } = to.query;
            await store.dispatch(action, id);
          }
          next();
        }
      },
      {
        name: "Relatório de clientes",
        path: "/relatorio-clientes",
        component: CustomersReports
      },
      {
        path: "/reports",
        name: "Relatórios",
        meta: null,
        component: Reports,
        children: [
          {
            path: "vendas",
            name: "Relatórios Venda",
            component: SalesReportsNeopag
          },
          {
            path: "recebimentos",
            name: "Relatórios Recebimento",
            component: PaymentsReportsNeopag
          },
          {
            path: "acordos",
            name: "Relatórios Acordos",
            component: DealsReportsNeopag
          },
          {
            path: "faturas",
            name: "Relatórios Faturas",
            component: StatementsReportsNeopag
          },
          {
            path: "boletos",
            name: "Relatórios Boletos",
            component: BilletsReportsNeopag
          },
          {
            path: "dynamic_1009",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1009",
                type: "question",
                title: "Clientes por Faixa de Atraso"
              }
            }
          },
          {
            path: "dynamic_1010",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1010",
                type: "question",
                title: "Clientes Quitados"
              }
            }
          },
          {
            path: "dynamic_1011",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1011",
                type: "question",
                title: "Clientes Sem Compras"
              }
            }
          },
          {
            path: "dynamic_1012",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1012",
                type: "question",
                title: "Posição Geral dos Clientes"
              }
            }
          },
          {
            path: "dynamic_1021",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1021",
                type: "question",
                title: "Informações Cadastrais Clientes"
              }
            }
          },
          {
            path: "dynamic_1022",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1022",
                type: "question",
                title: "Devedores por participação"
              }
            }
          },
          {
            path: "dynamic_1034",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1034",
                type: "question",
                title: "CRM WhattsApp"
              }
            }
          },
          {
            path: "dynamic_1122",
            name: "DynamicReport",
            component: DynamicReportsNeopag,
            meta: {
              params: {
                report_id: "1122",
                type: "question",
                title: "Repasses - Neopag Crédito"
              }
            }
          }
        ]
      },
      {
        path: "/configuracoes",
        component: Settings,
        beforeEnter: async (to, from, next) => {
          const { permissions } = await store.getters[
            types.AUTH_ACTIVE_STORE_DETAILS
          ];
          if (permissions && !permissions.settings) {
            return next("*");
          }
          next();
        },
        children: [
          {
            path: "",
            name: "Configurações",
            meta: {
              params: {
                tab: "config"
              }
            },
            component: SettingsCompany
          },
          {
            path: "marketing",
            name: "Marketing",
            meta: {
              params: {
                tab: "marketing"
              }
            },
            component: Marketing
          },
          {
            path: "resetar-senha",
            name: "Resetar Senha",
            meta: {
              params: {
                tab: "reset-password"
              }
            },
            component: ResetPassword
          },
          {
            path: "planos",
            name: "Configurações - Planos",
            meta: {
              params: {
                tab: "plans"
              }
            },
            component: SettingsPlans
          },
          {
            path: "boletos",
            name: "Configurações - Boletos",
            meta: {
              params: {
                tab: "billets"
              }
            },
            component: SettingsBillets
          },
          {
            path: "filiais",
            name: "Configurações - Filiais",
            meta: {
              params: {
                tab: "branches"
              }
            },
            component: SettingsBranches
          },
          {
            path: "colaboradores",
            name: "Configurações - Colaboradores",
            meta: {
              params: {
                tab: "staff"
              }
            },
            component: SettingsStaff
          },
          {
            path: "assinatura",
            meta: {
              params: {
                tab: "subscription"
              }
            },
            component: SettingsSubscription,
            children: [
              {
                path: "",
                name: "Configurações - Assinatura e pagamento",
                component: SubscriptionSummary,
                meta: {
                  params: {
                    tab: "subscription"
                  }
                }
              },
              // {
              //   path: "mudar-plano",
              //   name: "Assinatura - Planos",
              //   component: SubscriptionOptions,
              //   meta: {
              //     params: {
              //       tab: "subscription"
              //     }
              //   }
              // },
              {
                path: "pagamento",
                name: "Assinatura - Meio de pagamento",
                component: SubscriptionPayment,
                params: {
                  tab: "subscription"
                },
                beforeEnter: (to, from, next) => {
                  if (!to.params.planID) {
                    return next({
                      name: "Configurações - Assinatura e pagamento"
                    });
                  }
                  return next();
                }
              }
            ]
          }
        ]
      },
      {
        path: "/cartao-neopag",
        name: "Cartão Neopag",
        component: Card
      },
      {
        path: "/ajuda",
        name: "Ajuda",
        component: Help
      },
      {
        path: "/indicacoes",
        name: "Indicações",
        component: Promotions
      }
    ]
  },
  {
    path: "*",
    name: "404",
    component: P404
  }
];
