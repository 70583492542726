import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  list: [],
  details: {},
  latLong: {}
};

const getters = {
  [types.BRANCH_LIST]: "list",
  [types.BRANCH_DETAILS]: "details"
};

const mutations = {
  [types.BRANCH_LIST]: "list",
  [types.BRANCH_DETAILS]: "details"
};

const actions = {
  [types.BRANCH_LIST]: async ({ commit, dispatch }, { storeID, userID }) => {
    try {
      if (userID) {
        const response = await axios.get(
          `/stores/${storeID}/branches?user_id=${userID}`
        );
        await dispatch(types.STAFF_ACTIVE, { storeID, userID });
        await commit(types.BRANCH_LIST, response.data.data);
        return Promise.resolve(response);
      } else {
        const response = await axios.get(`/stores/${storeID}/branches`);
        await commit(types.BRANCH_LIST, response.data.data);
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.BRANCH_DETAILS]: async ({ commit }, { storeID, branchID }) => {
    try {
      const response = await axios.get(
        `/stores/${storeID}/branches/${branchID}`
      );
      commit(types.BRANCH_DETAILS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.BRANCH_CREATE]: async ({ dispatch }, { storeID, payload }) => {
    try {
      const response = await axios.post(`/stores/${storeID}/branches`, {
        branch: payload
      });
      await dispatch(types.BRANCH_LIST, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.BRANCH_UPDATE]: async (
    { dispatch },
    { storeID, branchID, payload }
  ) => {
    try {
      const response = await axios.put(
        `/stores/${storeID}/branches/${branchID}`,
        payload
      );
      await dispatch(types.BRANCH_LIST, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.BRANCH_DELETE]: async ({ dispatch }, { storeID, branchID }) => {
    try {
      const response = await axios.delete(
        `/stores/${storeID}/branches/${branchID}`
      );
      await dispatch(types.BRANCH_LIST, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.BRANCH_LINK_STAFF]: async (
    { dispatch },
    { storeID, branchID, staffID }
  ) => {
    try {
      const response = await axios.put(
        `/stores/${storeID}/branches/${branchID}/link_staff?staff_id=${staffID}`
      );
      await dispatch(types.BRANCH_LIST, { storeID });
      await dispatch(types.STAFF_LIST, { storeID });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.BRANCH_UNLINK_STAFF]: async (
    { dispatch },
    { storeID, branchID, staffID }
  ) => {
    try {
      const response = await axios.delete(
        `/stores/${storeID}/branches/${branchID}/unlink_staff?staff_id=${staffID}`
      );
      await dispatch(types.BRANCH_LIST, { storeID });
      await dispatch(types.STAFF_LIST, { storeID });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
