/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  cell: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M38.949 17.164h8.406v3.15h-8.406zM24.768 23.464h36.77v3.151h-36.77zM24.768 46.052h36.77v3.15h-36.77z"/><path pid="1" fill="#FF5E44" d="M32.646 53.932a2.103 2.103 0 0 1-4.204 0 2.102 2.102 0 0 1 4.204 0M38.953 53.932c0 1.16-.943 2.1-2.1 2.1a2.103 2.103 0 0 1-2.107-2.1 2.103 2.103 0 0 1 4.207 0M45.254 53.932c0 1.16-.941 2.1-2.102 2.1a2.1 2.1 0 1 1 0-4.201 2.1 2.1 0 0 1 2.102 2.101M51.559 53.932c0 1.16-.943 2.1-2.102 2.1a2.101 2.101 0 1 1 0-4.201c1.158 0 2.102.939 2.102 2.101M57.861 53.932a2.102 2.102 0 1 1-4.203-.003 2.102 2.102 0 0 1 4.203.003M32.646 60.234a2.102 2.102 0 0 1-4.204 0c0-1.162.943-2.1 2.104-2.1a2.1 2.1 0 0 1 2.1 2.1M38.953 60.234a2.1 2.1 0 0 1-2.1 2.101 2.102 2.102 0 0 1-2.107-2.101c0-1.162.943-2.1 2.107-2.1 1.157.001 2.1.938 2.1 2.1M45.254 60.234c0 1.163-.941 2.101-2.102 2.101a2.1 2.1 0 1 1 0-4.201 2.1 2.1 0 0 1 2.102 2.1M51.559 60.234a2.101 2.101 0 0 1-2.102 2.101 2.1 2.1 0 1 1 0-4.201 2.102 2.102 0 0 1 2.102 2.1M38.953 66.538a2.1 2.1 0 0 1-2.1 2.1 2.102 2.102 0 1 1 0-4.202c1.157.001 2.1.942 2.1 2.102M45.254 66.538c0 1.162-.941 2.1-2.102 2.1s-2.102-.938-2.102-2.1a2.103 2.103 0 0 1 4.204 0M51.559 66.538a2.1 2.1 0 0 1-2.102 2.1 2.1 2.1 0 1 1 0-4.202 2.105 2.105 0 0 1 2.102 2.102M57.861 60.234a2.1 2.1 0 0 1-2.1 2.101 2.1 2.1 0 1 1 0-4.201c1.157.001 2.1.938 2.1 2.1"/><path pid="2" fill="#69737D" d="M48.932 74.418H37.375c-7.818 0-14.182-6.364-14.182-14.184v-34.67c0-7.819 6.363-14.18 14.182-14.18h11.557c7.82 0 14.184 6.36 14.184 14.18v34.67c-.001 7.82-6.364 14.184-14.184 14.184M37.375 14.535c-6.082 0-11.029 4.948-11.029 11.029v34.67c0 6.083 4.947 11.029 11.029 11.029h11.557c6.084 0 11.027-4.946 11.027-11.029v-34.67c0-6.081-4.943-11.029-11.027-11.029H37.375z"/>`
  }
});
