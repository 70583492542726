/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  calendar: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M60.784 73.065H24.792c-5.558 0-10.079-4.521-10.079-10.079V26.994c0-5.558 4.521-10.079 10.079-10.079h35.992c5.558 0 10.079 4.521 10.079 10.079v35.992c0 5.558-4.521 10.079-10.079 10.079zm-35.992-53a6.937 6.937 0 0 0-6.929 6.929v35.992a6.937 6.937 0 0 0 6.929 6.929h35.992a6.937 6.937 0 0 0 6.929-6.929V26.994a6.937 6.937 0 0 0-6.929-6.929H24.792z"/><path pid="1" fill="#FF5E44" d="M32.954 19.949a1.666 1.666 0 0 1-3.332 0v-5.334a1.666 1.666 0 0 1 3.332 0v5.334zM40.621 19.949a1.666 1.666 0 1 1-3.333 0v-5.334a1.667 1.667 0 0 1 3.333 0v5.334zM48.288 19.949a1.667 1.667 0 1 1-3.334 0v-5.334a1.667 1.667 0 1 1 3.334 0v5.334zM55.954 19.949a1.667 1.667 0 0 1-3.333 0v-5.334a1.666 1.666 0 1 1 3.333 0v5.334z"/><path pid="2" fill="#69737D" d="M16.288 28.04h53v3.15h-53z"/><g><path pid="3" fill="#69737D" d="M24.204 36.865h6.418v6.417h-6.418zM34.454 36.865h6.418v6.417h-6.418zM44.704 36.865h6.418v6.417h-6.418zM54.954 36.865h6.418v6.417h-6.418z"/></g><g><path pid="4" fill="#69737D" d="M24.204 47.115h6.418v6.417h-6.418zM34.454 47.115h6.418v6.417h-6.418zM44.704 47.115h6.418v6.417h-6.418zM54.954 47.115h6.418v6.417h-6.418z"/></g><g><path pid="5" fill="#69737D" d="M24.204 57.365h6.418v6.417h-6.418zM34.454 57.365h6.418v6.417h-6.418zM44.704 57.365h6.418v6.417h-6.418zM54.954 57.365h6.418v6.417h-6.418z"/></g>`
  }
});
