const captcha = "6LfmAW0UAAAAAKz6UtbV6gFBB4jt0-xf8KWV_WCi";
// const captcha = "6LfmAW0UAAAAAKz6UtbV6gFBB4jt0";

// const avatar = name => `https://icotar.com/initials/${name}`;
const avatar = "/img/avatar.png";
const tz = "T00:00-03:00";
const breakpoints = {
  small: 768,
  medium: 960,
  large: Infinity
};
const subscriptionStatus = {
  partially_paid: "Pago",
  refunded: "Pago",
  paid: "Pago",
  pending: "Pendente",
  authorized: "Autorizado",
  in_protest: "Em protesto",
  chargeback: "Estornada",
  canceled: "Cancelada",
  expired: "Expirada"
};
const money = {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false
};
export { tz, money, avatar, captcha, breakpoints, subscriptionStatus };
