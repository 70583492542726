import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  staff: {},
  list: [],
  details: {},
  userSignUp: {}
};

const getters = {
  [types.STAFF_ACTIVE]: "staff",
  [types.STAFF_LIST]: "list",
  [types.STAFF_DETAILS]: "details"
};

const mutations = {
  [types.STAFF_ACTIVE]: "staff",
  [types.STAFF_LIST]: "list",
  [types.STAFF_DETAILS]: "details"
};

const actions = {
  [types.STAFF_ACTIVE]: async ({ commit }, { storeID, userID }) => {
    try {
      const response = await axios.get(
        `/stores/${storeID}/staffs/by_user/${userID}`
      );
      commit(types.STAFF_ACTIVE, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STAFF_LIST]: async ({ commit, dispatch }, { storeID }) => {
    try {
      const response = await axios.get(`/stores/${storeID}/staffs`);
      await commit(types.STAFF_LIST, response.data.data);
      await dispatch(types.BRANCH_LIST, { storeID });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STAFF_DETAILS]: async ({ commit }, { storeID, staffID }) => {
    try {
      const response = await axios.get(`/stores/${storeID}/staffs/${staffID}`);
      await commit(types.STAFF_DETAILS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STAFF_CREATE]: async ({ dispatch }, { storeID, payload }) => {
    try {
      const response = await axios.post(`/stores/${storeID}/staffs`, payload, {
        ignore: true
      });
      await dispatch(types.STAFF_LIST, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STAFF_UPDATE]: async ({ dispatch }, { storeID, staffID, payload }) => {
    try {
      const response = await axios.put(
        `/stores/${storeID}/staffs/${staffID}`,
        payload
      );
      dispatch(types.STAFF_LIST, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STAFF_DELETE]: async ({ dispatch }, { storeID, staffID }) => {
    try {
      const response = await axios.delete(
        `/stores/${storeID}/staffs/${staffID}`
      );
      dispatch(types.STAFF_LIST, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STAFF_SEARCH_BY_CPF]: async (_, { CPF }) => {
    try {
      const cleanCPF = CPF.replace(/\D/g, "");
      if (!cleanCPF.length) return false;
      const response = await axios.get(
        `/accounts/get_status_by_cpf?cpf=${cleanCPF}`
      );
      // commit(types.STAFF_SEARCH_BY_CPF, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
