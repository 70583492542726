/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  email: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#FF5E44" d="M22.26 38.697h40.81v3.14H22.26z"/><path pid="1" fill="#69737D" d="M69.347 71.136H15.981V35.433l16.678-16.682a14.036 14.036 0 0 1 9.988-4.137c3.774 0 7.323 1.469 9.989 4.139l16.71 16.676v35.707zM19.12 67.997h47.088V36.732L50.419 20.974a10.912 10.912 0 0 0-7.771-3.221 10.921 10.921 0 0 0-7.77 3.219L19.12 36.731v31.266z"/><path pid="2" fill="#69737D" d="M66.669 70.678l-16.25-16.219a10.914 10.914 0 0 0-7.77-3.22 10.92 10.92 0 0 0-7.77 3.217l-16.22 16.219-2.219-2.217 16.221-16.221a14.033 14.033 0 0 1 9.988-4.138c3.773 0 7.321 1.47 9.987 4.138l16.25 16.219-2.217 2.222z"/><path pid="3" fill="#69737D" d="M18.605 34.922l17.53 15.957-2.115 2.323-17.53-15.958zM66.637 35.01l2.113 2.323-17.448 15.863-2.112-2.324z"/>`
  }
});
