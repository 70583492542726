/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "error-circle": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M42.573 73.793c-16.874 0-30.605-13.728-30.605-30.605S25.699 12.58 42.573 12.58c16.88 0 30.608 13.73 30.608 30.608S59.453 73.793 42.573 73.793m0-58.073c-15.145 0-27.467 12.321-27.467 27.468 0 15.146 12.322 27.468 27.467 27.468 15.15 0 27.471-12.322 27.471-27.468 0-15.147-12.32-27.468-27.471-27.468"/><path pid="1" fill="#FF5E44" d="M39.392 55.576h6.368v6.368h-6.368v-6.368zM38.562 24.427h8.025L45.25 51.13H39.9l-1.338-26.703z"/>`
  }
});
