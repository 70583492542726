/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "success-circle": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M42.908 73.935c-16.928 0-30.7-13.772-30.7-30.7s13.772-30.7 30.7-30.7 30.7 13.772 30.7 30.7-13.772 30.7-30.7 30.7zm0-58.25c-15.191 0-27.55 12.358-27.55 27.55s12.358 27.55 27.55 27.55 27.55-12.358 27.55-27.55-12.358-27.55-27.55-27.55z"/><path pid="1" fill="#0CF9CD" d="M37.263 57.211l-1.207-1.066-12.185-12.19 2.229-2.226 11.093 11.097 22.525-22.523 2.226 2.226z"/>`
  }
});
