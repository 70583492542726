import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";

// const getTodayOffset = arr => {
//   return arr.indexOf(
//     arr.find(it => {
//       const d = new Date(it.date);
//       const t = new Date();
//       const m = d.getMonth() === t.getMonth();
//       const y = d.getFullYear() === t.getFullYear();
//       return m && y;
//     })
//   );
// };

// const fillIncome = array => {
//   const initialOffset = getTodayOffset(array);
//   if (initialOffset >= 0) {
//     const newArray = [...array]
//     let futureIterations = 12 - newArray.length - getTodayOffset(newArray) + 1;
//     let pastIterations = getTodayOffset(newArray) - 1;
//     const fill = factor => {
//       if (factor === 1) {
//         while (futureIterations) {
//           const lastDate = newArray[newArray.length - 1];
//           const lastDateValue = lastDate ? lastDate.date : false;
//           const newDate = lastDateValue ? new Date(lastDateValue) : new Date();
//           newDate.setMonth(newDate.getMonth() + factor);
//           newArray.push({
//             date: newDate.toISOString(),
//             value: 0,
//             amount: 0
//           });
//           futureIterations--;
//         }
//       } else {
//         while (pastIterations) {
//           const firstDate = newArray[0].date;
//           const newDate = new Date(firstDate);
//           newDate.setMonth(newDate.getMonth() + factor);
//           newArray.unshift({
//             date: newDate.toISOString(),
//             value: 0,
//             amount: 0
//           });
//           pastIterations--;
//         }
//       }
//     };

//     if (futureIterations) {
//       fill(1);
//     }
//     if (pastIterations) {
//       fill(-1);
//     }
//     return newArray;
//   } else {
//     return array;
//   }
// };

// const clearIncome = raw => {
//   let cleared = [];
//   raw
//     .sort((a, b) => {
//       const A = new Date(a.date).getTime();
//       const B = new Date(b.date).getTime();
//       return A - B;
//     })
//     .reduce((acc, cur) => {
//       const date = new Date(cur.date);
//       const key = `${date.getFullYear()}-${date.getMonth() + 1}`;
//       if (!acc[key]) {
//         acc[key] = {
//           value: 0,
//           date: cur.date
//         };
//         cleared.push(acc[key]);
//       }
//       acc[key].value += +cur.value;
//       return acc;
//     }, {})

//   const result = cleared.reduce(function (hash) {
//     return (p, c) => {
//       const currentDate = new Date(c.date);
//       const currentMonth = currentDate.getMonth();
//       const missingMonthNo = currentMonth - hash.prev
//       if (hash.prev && missingMonthNo > 1) {
//         for (let i = 1; i < missingMonthNo; i++) {
//           p.push({
//             value: 0,
//             amount: 0,
//             date: new Date(currentDate.setMonth(currentMonth + i - 2)).toISOString()
//           });
//         }
//       }
//       hash.prev = currentMonth;
//       p.push(c);
//       return p;
//     };
//   }(Object.create(null)), [])

//   cleared = fillIncome(result);
//   return cleared;
// };

const state = {
  definitions: false,
  clientWallet: [],
  creditWallet: [],
  saleHistory: [],
  income: []
};

const getters = {
  [types.DASHBOARD_DEFINITIONS]: "definitions",
  [types.DASHBOARD_CLIENT_WALLET]: "clientWallet",
  [types.DASHBOARD_CREDIT_WALLET]: "creditWallet",
  [types.DASHBOARD_SALE_HISTORY]: "saleHistory",
  [types.DASHBOARD_INCOME]: "income"
};

const mutations = {
  [types.DASHBOARD_DEFINITIONS]: "definitions",
  [types.DASHBOARD_CLIENT_WALLET]: "clientWallet",
  [types.DASHBOARD_CREDIT_WALLET]: "creditWallet",
  [types.DASHBOARD_SALE_HISTORY]: "saleHistory",
  [types.DASHBOARD_INCOME]: "income"
};

const actions = {
  [types.DASHBOARD_DEFINITIONS]: async ({
    commit,
    rootState
  }) => {
    const storeID = rootState.auth.store_id;
    const response = await axios.get(`/stores/${storeID}/dashboard`);
    const definitions = {};
    response.data.data.map(it => {
      definitions[it.period] = { ...it
      };
    });
    commit(types.DASHBOARD_DEFINITIONS, definitions);
    return response;
  },
  [types.DASHBOARD_CHARTS]: async ({
    commit,
    rootState
  }) => {
    const storeID = rootState.auth.store_id;
    const response = await axios.get(`/stores/${storeID}/dashboard_charts`);

    const {
      income,
      // eslint-disable-next-line
      wallet
    } = response.data.data;

  // await commit(types.DASHBOARD_INCOME, clearIcome(income));
    await commit(types.DASHBOARD_INCOME, income);
    await commit(types.DASHBOARD_CLIENT_WALLET, wallet);
    await commit(types.DASHBOARD_CREDIT_WALLET, wallet);
    return response;
  },
  [types.DASHBOARD_CLIENT_WALLET]: ({
    commit
  }, payload) => {
    commit(types.DASHBOARD_CLIENT_WALLET, payload);
  },
  [types.DASHBOARD_CREDIT_WALLET]: ({
    commit
  }, payload) => {
    commit(types.DASHBOARD_CREDIT_WALLET, payload);
  },
  [types.DASHBOARD_INCOME]: ({
    commit
  }, payload) => {
    commit(types.DASHBOARD_INCOME, payload);
  },
  [types.DASHBOARD_SALE_HISTORY]: async ({
    commit,
    rootState
  }) => {
    const storeID = await rootState.auth.store_id;
    const response = await axios.get(`stores/${storeID}/sales`);
    const cleantHistory = response.data.data
      .sort((a, b) => {
        const A = new Date(a.sale_date).getTime();
        const B = new Date(b.sale_date).getTime();
        return A - B;
      });
    commit(types.DASHBOARD_SALE_HISTORY, cleantHistory);
    return response;
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
