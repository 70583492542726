import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from '@/helpers/errorHandler';

const state = {
  plans: [],
  details: {
    valid: false
  },
  moreDetails: {},
  installments: []
};

const getters = {
  [types.DEAL_PLANS]: "plans",
  [types.DEAL_DETAILS]: "details",
  [types.DEAL_MORE_DETAILS]: "moreDetails",
  [types.DEAL_INSTALLMENTS]: "installments"
};

const mutations = {
  [types.DEAL_PLANS]: "plans",
  [types.DEAL_DETAILS]: "details",
  [types.DEAL_MORE_DETAILS]: "moreDetails",
  [types.DEAL_INSTALLMENTS]: "installments"
};

const actions = {
  [types.DEAL_DETAILS]: ({ commit }, details) => {
    commit(types.DEAL_DETAILS, details);
  },
  [types.DEAL_PLANS]: async ({ commit, rootState }) => {
    try {
      const storeID = rootState.auth.store_id;
      const response = await axios.get(`/stores/${storeID}/deal_plans`);
      commit(types.DEAL_PLANS, response.data.data);
      return Promise.resolve(response);
    } catch(error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.DEAL_INSTALLMENTS]: async ({ commit }, payload) => {
    try {
      const response = await axios.post(
        "/sales/calculate_installments",
        payload,
        {
          ignore: true
        }
      );
      response.data.installments.sort((a, b) => {
        return a.number - b.number;
      });
      commit(types.DEAL_INSTALLMENTS, response.data);
      return Promise.resolve(response);
    } catch(error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.DEAL_SUBMIT]: async ({ getters, commit }, payload) => {
    try {
      if (getters[types.AUTH_ACTIVE_BRANCH]) {
        payload.deal.store_branch_id = getters[types.AUTH_ACTIVE_BRANCH];
      }
      const response = await axios.post(`/sales/deal`, payload, {
        ignore: true
      });
      commit(types.DEAL_MORE_DETAILS, response.data.data);
      return Promise.resolve(response);
    } catch(error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.DEAL_RESET]: ({ commit }) => {
    commit(types.PAYMENT_DETAILS, {
      is_valid: false
    });
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
