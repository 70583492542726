import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  list: [],
  reportsPayments: [],
  reportsDeals: [],
  statements: [],
  billets: {},
  sale: {},
  statementsTotals: {}
};

const getters = {
  [types.REPORTS]: "list",
  [types.REPORTS_PAYMENTS]: "reportsPayments",
  [types.REPORTS_DEALS]: "reportsDeals",
  [types.REPORTS_STATEMENTS]: "statements",
  [types.REPORTS_BILLETS]: "billets",
  [types.REPORTS_SALE_INFO]: "sale",
  [types.REPORTS_STATEMENTS_TOTALS]: "statementsTotals"
};

const mutations = {
  [types.REPORTS]: "list",
  [types.REPORTS_PAYMENTS]: "reportsPayments",
  [types.REPORTS_DEALS]: "reportsDeals",
  [types.REPORTS_STATEMENTS]: "statements",
  [types.REPORTS_BILLETS]: "billets",
  [types.REPORTS_SALE_INFO]: "sale",
  [types.REPORTS_STATEMENTS_TOTALS]: "statementsTotals"
};

const actions = {
  [types.REPORTS]: async (
    store,
    {
      start,
      end,
      order_by = "kind_asc",
      status = "all",
      customHeader = false,
      type = "sales",
      page = 1,
      page_size,
      q
    }
  ) => {
    try {
      const config = {};
      const storeID = store.rootState.auth.store_id;
      const branchID = store.rootState.auth.branch_id;
      const params = {
        initial_date: start.toISOString(),
        end_date: end.toISOString(),
        page,
        page_size,
        q
      };

      if (branchID) {
        params.store_branch_id = branchID;
      }

      if (customHeader) {
        config.headers = customHeader;
        config.responseType = "blob";
        delete params.page_size;
      }

      switch (status) {
        case "active":
          params.active = true;
          break;
        case "canceled":
          params.active = false;
          break;
        default:
          break;
      }

      if (!customHeader) {
        config.params = params;
      } else {
        config.params = {
          initial_date: start.toISOString(),
          end_date: end.toISOString(),
          q,
          store_branch_id: branchID
        };
      }

      const response = await axios.get(
        `stores/${storeID}/reports/${type}?order_by=${order_by}`,
        config
      );

      if (!customHeader) {
        response.data.data.sort((a, b) => {
          const A = new Date(a.date).getTime();
          const B = new Date(b.date).getTime();
          return B - A;
        });

        let list = response.data.data;

        if (branchID) {
          list = list.filter(it => {
            return it.branch ? it.branch.id === branchID : false;
          });
        }

        store.commit(
          types.REPORTS,
          Object.assign({}, response.data, { data: list })
        );
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REPORTS_PAYMENTS]: async (
    store,
    {
      start,
      end,
      order_by = "kind_asc",
      status = "all",
      customHeader = false,
      type = "payments",
      page = 1,
      page_size,
      q
    }
  ) => {
    try {
      const config = {};
      const storeID = store.rootState.auth.store_id;
      const branchID = store.rootState.auth.branch_id;
      const params = {
        initial_date: start.toISOString(),
        end_date: end.toISOString(),
        page,
        page_size,
        q
      };

      if (branchID) {
        params.store_branch_id = branchID;
      }

      if (customHeader) {
        config.headers = customHeader;
        config.responseType = "blob";
        delete params.page_size;
      }

      switch (status) {
        case "active":
          params.active = true;
          break;
        case "canceled":
          params.active = false;
          break;
        default:
          break;
      }

      if (!customHeader) {
        config.params = params;
      } else {
        config.params = {
          initial_date: start.toISOString(),
          end_date: end.toISOString(),
          q,
          store_branch_id: branchID
        };
      }

      const response = await axios.get(
        `stores/${storeID}/reports/${type}?order_by=${order_by}`,
        config
      );

      if (!customHeader) {
        response.data.data.sort((a, b) => {
          const A = new Date(a.date).getTime();
          const B = new Date(b.date).getTime();
          return B - A;
        });

        let reportsPayments = response.data.data;

        if (branchID) {
          reportsPayments = reportsPayments.filter(it => {
            return it.branch ? it.branch.id === branchID : false;
          });
        }

        store.commit(
          types.REPORTS_PAYMENTS,
          Object.assign({}, response.data, { data: reportsPayments })
        );
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REPORTS_DEALS]: async (
    store,
    {
      start,
      end,
      order_by = "kind_asc",
      status = "all",
      customHeader = false,
      type = "deals",
      page = 1,
      page_size,
      q
    }
  ) => {
    try {
      const config = {};
      const storeID = store.rootState.auth.store_id;
      const branchID = store.rootState.auth.branch_id;
      const params = {
        initial_date: start.toISOString(),
        end_date: end.toISOString(),
        page,
        page_size,
        q
      };

      if (branchID) {
        params.store_branch_id = branchID;
      }

      if (customHeader) {
        config.headers = customHeader;
        config.responseType = "blob";
        delete params.page_size;
      }

      switch (status) {
        case "active":
          params.active = true;
          break;
        case "canceled":
          params.active = false;
          break;
        default:
          break;
      }

      if (!customHeader) {
        config.params = params;
      } else {
        config.params = {
          initial_date: start.toISOString(),
          end_date: end.toISOString(),
          q,
          store_branch_id: branchID
        };
      }

      const response = await axios.get(
        `stores/${storeID}/reports/${type}?order_by=${order_by}`,
        config
      );

      if (!customHeader) {
        response.data.data.sort((a, b) => {
          const A = new Date(a.date).getTime();
          const B = new Date(b.date).getTime();
          return B - A;
        });

        let reportsDeals = response.data.data;

        if (branchID) {
          reportsDeals = reportsDeals.filter(it => {
            return it.branch ? it.branch.id === branchID : false;
          });
        }
        store.commit(
          types.REPORTS_DEALS,
          Object.assign({}, response.data, { data: reportsDeals })
        );
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REPORTS_STATEMENTS]: async (
    store,
    {
      start,
      end,
      order_by = "date_asc",
      page = 1,
      page_size,
      customHeader = false,
      q
    }
  ) => {
    try {
      const config = {};
      const storeID = store.rootState.auth.store_id;
      const params = {
        start_date: start.toISOString(),
        end_date: end.toISOString(),
        order_by,
        q,
        page,
        page_size
      };

      if (customHeader) {
        config.headers = customHeader;
        config.responseType = "blob";
        delete params.page_size;
      }

      if (!customHeader) {
        config.params = params;
      } else {
        config.params = {
          start_date: start.toISOString(),
          q,
          end_date: end.toISOString()
        };
      }

      const response = await axios.get(
        `stores/${storeID}/reports/statements`,
        config
      );

      if (!customHeader) {
        response.data.data.sort((a, b) => {
          const A = new Date(a.due_date).getTime();
          const B = new Date(b.due_date).getTime();
          return B - A;
        });

        store.commit(types.REPORTS_STATEMENTS, response.data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REPORTS_BILLETS]: async (
    { commit },
    {
      store_id,
      start,
      end,
      page = 1,
      page_size,
      order_by = "date_asc",
      status = "all",
      customHeader = false,
      billetsFilterTypeDate
    }
  ) => {
    try {
      if (store_id) {
        const config = {};
        if (customHeader) {
          config.headers = customHeader;
          config.responseType = "blob";
        }
        if (billetsFilterTypeDate) {
          status = "paid";
        }
        let url = "";
        if (status === "all" && !billetsFilterTypeDate) {
          url = `/stores/${store_id}/billets/reports?start_due_date=${start.toISOString()}&end_date=${end.toISOString()}&page=${page}&page_size=${page_size}&order_by=${order_by}`;
        } else if (billetsFilterTypeDate) {
          url = `/stores/${store_id}/billets/reports?start_payment_date=${start.toISOString()}&end_date=${end.toISOString()}&page=${page}&page_size=${page_size}&order_by=${order_by}&status=${status}`;
        } else {
          url = `/stores/${store_id}/billets/reports?start_due_date=${start.toISOString()}&end_date=${end.toISOString()}&page=${page}&page_size=${page_size}&order_by=${order_by}&status=${status}`;
        }
        const response = await axios.get(url, config);
        if (!customHeader) {
          commit(types.REPORTS_BILLETS, response.data);
          return Promise.resolve(response.data);
        }
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REPORTS_SALE_INFO]: async ({ commit }, saleID) => {
    try {
      const response = await axios.get(`sales/${saleID}`);
      commit(types.REPORTS_SALE_INFO, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.REPORTS_STATEMENTS_TOTALS]: async (
    { commit },
    { storeID, start_date, end_date, q }
  ) => {
    const config = {};
    const params = {
      start_date,
      end_date,
      q
    };
    config.params = params;
    try {
      const response = await axios.get(
        `stores/${storeID}/reports/statement_totals`,
        config
      );
      commit(types.REPORTS_STATEMENTS_TOTALS, response.data.data);
      return Promise.resolve(response);
      return;
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
