import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  user: {},
  auth_origin: null,
  token: null,
  store_id: null,
  store_details: {},
  branch_id: null,
  branch_details: {},
  branch_choice: false,
  openBearer: null,
  showEmailFeedback: false,
  signUpStep: ""
};

const getters = {
  [types.AUTH]: state => !!state.token,
  [types.AUTH_USER]: "user",
  [types.AUTH_ORIGIN]: "auth_origin",
  [types.AUTH_TOKEN]: "token",
  [types.AUTH_ACTIVE_STORE]: "store_id",
  [types.AUTH_ACTIVE_STORE_DETAILS]: "store_details",
  [types.AUTH_ACTIVE_BRANCH]: "branch_id",
  [types.AUTH_ACTIVE_BRANCH_DETAILS]: "branch_details",
  [types.AUTH_ACTIVE_BRANCH_CHOICE]: "branch_choice",
  [types.AUTH_OPEN_BEARER]: "openBearer",
  [types.AUTH_SHOW_EMAIL_FEEDBACK]: "showEmailFeedback",
  [types.AUTH_CAMES_FROM_SIGNUP]: "signUpStep"
};

const mutations = {
  [types.AUTH_USER]: "user",
  [types.AUTH_TOKEN]: "token",
  [types.AUTH_ORIGIN]: "auth_origin",
  [types.AUTH_ACTIVE_STORE]: "store_id",
  [types.AUTH_ACTIVE_STORE_DETAILS]: "store_details",
  [types.AUTH_ACTIVE_BRANCH]: "branch_id",
  [types.AUTH_ACTIVE_BRANCH_DETAILS]: "branch_details",
  [types.AUTH_ACTIVE_BRANCH_CHOICE]: "branch_choice",
  [types.AUTH_OPEN_BEARER]: "openBearer",
  [types.AUTH_SHOW_EMAIL_FEEDBACK]: "showEmailFeedback",
  [types.AUTH_CAMES_FROM_SIGNUP]: "signUpStep"
};

const actions = {
  [types.AUTH_LOGIN]: async ({ commit }, payload) => {
    try {
      const data = payload;
      data.origin = "store_admin";
      const response = await axios.post("/accounts/login", data);
      const { token, user } = response.data.data;
      commit(types.AUTH_TOKEN, token);
      commit(types.AUTH_USER, user);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.AUTH_LOGOUT]: async ({ commit }) => {
    await commit(types.AUTH_TOKEN, null);
    await commit(types.AUTH_ACTIVE_STORE, null);
    await commit(types.AUTH_ACTIVE_STORE_DETAILS, {});
    await commit(types.AUTH_USER, null);
    await commit(types.AUTH_ACTIVE_BRANCH, null);
    await commit(types.AUTH_ACTIVE_BRANCH_DETAILS, {});
    await commit(types.AUTH_ACTIVE_BRANCH_CHOICE, false);
    await commit(types.AUTH_CAMES_FROM_SIGNUP, null);
  },
  [types.AUTH_USER]: ({ commit }, user) => {
    commit(types.AUTH_USER, user);
  },
  [types.AUTH_ORIGIN]: ({ commit }, origin) => {
    commit(types.AUTH_ORIGIN, origin);
  },
  [types.AUTH_TOKEN]: ({ commit }, token) => {
    commit(types.AUTH_TOKEN, token);
  },
  [types.AUTH_SHOW_EMAIL_FEEDBACK]: ({ commit }, fb) => {
    commit(types.AUTH_SHOW_EMAIL_FEEDBACK, !!fb);
  },
  [types.AUTH_ACTIVE_STORE]: async (
    { commit, dispatch },
    { storeID, userID }
  ) => {
    await dispatch(types.BRANCH_LIST, { storeID, userID });
    commit(types.AUTH_ACTIVE_STORE, storeID);
  },
  [types.AUTH_ACTIVE_STORE_DETAILS]: ({ commit }, store) => {
    commit(types.AUTH_ACTIVE_STORE_DETAILS, store);
  },
  [types.AUTH_ACTIVE_BRANCH]: ({ commit }, branch) => {
    commit(types.AUTH_ACTIVE_BRANCH, branch);
  },
  [types.AUTH_ACTIVE_BRANCH_DETAILS]: ({ commit }, branch) => {
    commit(types.AUTH_ACTIVE_BRANCH_DETAILS, branch);
  },
  [types.AUTH_ACTIVE_BRANCH_CHOICE]: ({ commit }, branch) => {
    commit(types.AUTH_ACTIVE_BRANCH_CHOICE, branch);
  },
  [types.AUTH_CHECK_USER]: async (ctx, params) => {
    try {
      const response = await axios.get("/accounts/get_status_by", {
        params: {
          ...params,
          ignore: true
        }
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.AUTH_RESET_PASSWORD]: async (ctx, { userId, password }) => {
    try {
      const response = await axios.put(
        `/accounts/users/${userId}/update_password`,
        {
          user: { password }
        }
      );
      return Promise.resolve(response);
    } catch (error) {
      return errorHandler(error.response);
    }
  },
  [types.AUTH_OPEN_BEARER]: async ({ commit }) => {
    try {
      const payload = {
        origin: "app_customer",
        email: "app.neopag@neopag.com",
        password: "123AppNeoPag"
      };
      const response = await axios.post("/accounts/login", payload);
      commit(types.AUTH_OPEN_BEARER, response.data.data.token);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.AUTH_VERIFY_CAPTCHA]: async (ctx, response) => {
    try {
      const resp = await axios.get("/verify_recaptcha", {
        params: {
          response
        }
      });
      return Promise.resolve(resp);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.AUTH_EMAIL_CONFIRMATION]: async (ctx, storeID) => {
    try {
      const response = await axios.post(`/emails/confirmation`, {
        store_id: storeID
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.AUTH_CAMES_FROM_SIGNUP]: ({ commit }, step) => {
    commit(types.AUTH_CAMES_FROM_SIGNUP, step);
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
