/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  bell: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M62.226 62.731l-37.65-.002v-20.24c0-10.746 8.621-19.825 18.825-19.825s18.825 9.079 18.825 19.825v20.242zm-34.5-3.152l31.35.002V42.489c0-8.883-7.324-16.675-15.675-16.675s-15.675 7.792-15.675 16.675v17.09z"/><path pid="1" fill="#0CF9CD" d="M34.001 42.489h-3.15c0-7.147 5.747-13.186 12.55-13.186v3.15c-5.007.001-9.4 4.691-9.4 10.036z"/><path pid="2" fill="#69737D" d="M43.525 69.939c-4.797 0-8.7-3.903-8.7-8.7v-1.575h17.4v1.575c.001 4.797-3.903 8.7-8.7 8.7zm-5.322-7.125c.681 2.295 2.809 3.975 5.322 3.975s4.642-1.68 5.322-3.975H38.203zM47.226 23.864h-3.15v-4.5a.676.676 0 0 0-1.35 0v4.5h-3.15v-4.5c0-2.109 1.716-3.825 3.825-3.825s3.825 1.716 3.825 3.825v4.5z"/>`
  }
});
