import Vue from "vue";
import App from "./App.vue";
import store from "./store/";
import VueI18n from "vue-i18n";
import router from "./router/";
import Plugins from "./plugins";
import i18nConfig from "./content/i18n.js";
import LogRocket from 'logrocket';

console.clear();

Vue.config.productionTip = false;

Vue.use(Plugins);

const i18n = new VueI18n(i18nConfig);

const baseHost = 'store.neopag.com'

if (window.location.host === baseHost) {
  LogRocket.init('guyahi/neopag-store', {
    rootHostname: baseHost
  });
}

Vue.prototype.$bus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
