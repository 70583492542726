/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  menu: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M58.379 33.818H27.047a4.914 4.914 0 0 1-4.909-4.909 4.914 4.914 0 0 1 4.909-4.908h31.332a4.914 4.914 0 0 1 4.909 4.908 4.914 4.914 0 0 1-4.909 4.909zm-31.332-6.667c-.97 0-1.759.788-1.759 1.758s.789 1.759 1.759 1.759h31.332c.97 0 1.759-.789 1.759-1.759s-.789-1.758-1.759-1.758H27.047zM58.379 47.818H27.047a4.914 4.914 0 0 1-4.909-4.909 4.914 4.914 0 0 1 4.909-4.908h31.332a4.914 4.914 0 0 1 4.909 4.908 4.914 4.914 0 0 1-4.909 4.909zm-31.332-6.667c-.97 0-1.759.788-1.759 1.758s.789 1.759 1.759 1.759h31.332c.97 0 1.759-.789 1.759-1.759s-.789-1.758-1.759-1.758H27.047zM58.379 61.818H27.047a4.914 4.914 0 0 1-4.909-4.909 4.914 4.914 0 0 1 4.909-4.908h31.332a4.914 4.914 0 0 1 4.909 4.908 4.914 4.914 0 0 1-4.909 4.909zm-31.332-6.667c-.97 0-1.759.788-1.759 1.758s.789 1.759 1.759 1.759h31.332c.97 0 1.759-.789 1.759-1.759s-.789-1.758-1.759-1.758H27.047z"/>`
  }
});
