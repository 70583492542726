import PerfectScrollbar from "perfect-scrollbar";

let ps;

const scrollInterval = el => {
  let ticks = 0;
  const i = setInterval(() => {
    ps.update(el);
    ticks++;
    if (ticks >= 20) clearInterval(i);
  }, 200);
};

export default {
  name: "scroll",
  inserted(el, binding) {
    if (binding.value) {
      const callback =
        typeof binding.value === "function" ?
        binding.value :
        binding.value.callback;

      el.addEventListener("scroll", callback, {
        passive: true
      });
    }

    el.style.position = "relative";
    ps = new PerfectScrollbar(el);
    scrollInterval(el);
  },
  unbind(el, binding) {
    if (binding.value) {
      el.removeEventListener("scroll", binding.callback, {
        passive: true
      });
    }
    el.style.position = "static";
    ps.destroy(el);
  },
  componentUpdated(el) {
    ps.update(el);
    scrollInterval(el);
  }
};
