/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "bag-check": {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M65 28.4l4 36c.6 5-3.8 8.5-8.5 8.5h-36c-4.7 0-8.5-3.8-8.5-8.5l4-36h45z"/><path pid="1" class="st0" d="M57.8 37v-8.5c0-8.5-6.9-16.3-15.3-16.3S27.2 20 27.2 28.5V37"/><path pid="2" class="st1" d="M52.7 44L39.3 57.3l-6.9-6.9"/>`
  }
});
