import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [types.BILLET_CANCEL]: async ({ dispatch }, payload) => {
    try {
      const response = await axios.put(
        `/banking/billets/${payload.billet_id}/cancel`
      );
      await dispatch(types.CLIENT_LOOSE_BILLS, {
        storeCustomerID: payload.client_customer_id
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
