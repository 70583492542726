/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "avatar-plus": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#0CF9CD" d="M54.578 23.239h3.15v14.25h-3.15z"/><path pid="1" fill="#0CF9CD" d="M49.195 28.79h14.25v3.15h-14.25z"/><path pid="2" fill="#69737D" d="M62.354 66.207h-3.15c0-4.454-3.624-8.078-8.078-8.078H34.674c-4.454 0-8.078 3.624-8.078 8.078h-3.15c0-6.191 5.037-11.229 11.229-11.229h16.451c6.19.001 11.228 5.038 11.228 11.229z"/><path pid="3" fill="#69737D" d="M42.899 57.845c-6.971 0-12.643-5.672-12.643-12.643 0-6.972 5.672-12.644 12.643-12.644s12.643 5.672 12.643 12.644c0 6.971-5.672 12.643-12.643 12.643zm0-22.136c-5.234 0-9.492 4.259-9.492 9.493s4.258 9.492 9.492 9.492 9.492-4.258 9.492-9.492-4.257-9.493-9.492-9.493z"/><path pid="4" fill="#69737D" d="M42.898 52.558a7.246 7.246 0 0 1-6.264-3.625l2.73-1.57a4.086 4.086 0 0 0 3.533 2.045 4.09 4.09 0 0 0 3.528-2.035l2.725 1.58a7.249 7.249 0 0 1-6.252 3.605z"/><path pid="5" fill="#69737D" d="M42.865 73.433c-16.928 0-30.7-13.772-30.7-30.7s13.772-30.7 30.7-30.7 30.7 13.772 30.7 30.7-13.772 30.7-30.7 30.7zm0-58.25c-15.191 0-27.55 12.358-27.55 27.55s12.358 27.55 27.55 27.55 27.55-12.358 27.55-27.55-12.358-27.55-27.55-27.55z"/>`
  }
});
