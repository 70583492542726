/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "close-circle": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#FF5E44" d="M28.89 27.76l30.03 30.03-2.226 2.227-30.032-30.03z"/><path pid="1" fill="#FF5E44" d="M56.304 27.76l2.226 2.227L28.5 60.017l-2.227-2.226z"/><path pid="2" fill="#69737D" d="M42.634 73.885c-16.928 0-30.7-13.772-30.7-30.7s13.772-30.7 30.7-30.7 30.7 13.772 30.7 30.7-13.772 30.7-30.7 30.7zm0-58.25c-15.191 0-27.55 12.358-27.55 27.55s12.358 27.55 27.55 27.55 27.55-12.358 27.55-27.55-12.359-27.55-27.55-27.55z"/>`
  }
});
