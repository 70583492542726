import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  details: {
    valid: false
  },
  moreDetails: undefined,
  installments: {
    total_amount: 0,
    installments: []
  },
  lastSale: ""
};

const getters = {
  [types.SALE_DETAILS]: "details",
  [types.SALE_MORE_DETAILS]: "moreDetails",
  [types.SALE_INSTALLMENTS]: "installments",
  [types.SALE_LAST_SALE]: "lastSale"
};

const mutations = {
  [types.SALE_DETAILS]: "details",
  [types.SALE_MORE_DETAILS]: "moreDetails",
  [types.SALE_INSTALLMENTS]: "installments",
  [types.SALE_LAST_SALE]: "lastSale"
};

const actions = {
  [types.SALE_INSTALLMENTS]: async ({ commit }, payload) => {
    try {
      const response = await axios.post(
        "/sales/calculate_installments",
        payload,
        {
          ignore: true
        }
      );
      response.data.installments.sort((a, b) => {
        return a.number - b.number;
      });
      commit(types.SALE_INSTALLMENTS, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SALE_DETAILS]: ({ commit }, details) => {
    commit(types.SALE_DETAILS, details);
  },
  [types.SALE_RECORD]: async (store, { saleID, payload }) => {
    try {
      const response = await axios.post(`/sales/${saleID}/record`, payload);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SALE_NEXT_DUE_DATE]: async (store, params) => {
    try {
      params.ignore = true;
      const response = await axios.get(`/sales/next_due_date`, {
        params
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SALE_MORE_DETAILS]: async ({ commit }, saleID) => {
    try {
      const response = await axios.get(`/sales/${saleID}`);
      commit(types.SALE_MORE_DETAILS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SALE]: async ({ getters, commit }, payload) => {
    try {
      if (getters[types.AUTH_ACTIVE_BRANCH]) {
        payload.sale.store_branch_id = getters[types.AUTH_ACTIVE_BRANCH];
      }
      const response = await axios.post("/sales", payload);
      commit(types.SALE_MORE_DETAILS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SALE_CANCEL]: async (store, saleID) => {
    try {
      if (!saleID) return false;
      const response = await axios.put(`/sales/${saleID}/cancel`);
      const reports = store.getters[types.REPORTS];
      if (reports.length)
        store.commit(
          types.REPORTS,
          reports.filter(it => it.id !== saleID)
        );
      return response;
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SALE_RESET]: ({ commit }) => {
    commit(types.SALE_DETAILS, {
      is_valid: false,
      down_payment: [],
      installment: {},
      description: "",
      value: 0
    });
  },
  [types.SALE_LAST_SALE]: ({ commit }, id) => {
    commit(types.SALE_LAST_SALE, id);
  },
  [types.SALE_RECEIPT_WHATSAPP]: (ctx, message) => {
    console.log(message);
    // commit(types.SALE_LAST_SALE, id);
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
