/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  compra: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M68.8 46.5l-9.2 9.2-4.7-4.7"/><path pid="1" class="st1" d="M29.6 38.8h2.5c.3 1.8 1.6 2.8 3.5 2.8 1.7 0 2.7-.8 2.7-2s-.7-1.9-2.3-2.3l-2.8-.7c-2.2-.6-3.4-2.3-3.4-4.6 0-3.1 2.3-4.5 5.2-4.5 3.2 0 5.1 1.8 5.5 4.5H38c-.3-1.3-1.4-2.1-3-2.1-1.9 0-2.7.9-2.7 2.1 0 1.2.8 1.9 1.8 2.1l2.8.7c2.5.6 3.9 2.2 3.9 4.8 0 2.6-1.9 4.4-5.3 4.4-3.7 0-5.7-2.2-5.9-5.2"/><path pid="2" class="st1" d="M33.9 25.3h2.6v2.8h-2.6zM33.9 43.4h2.6v2.8h-2.6z"/><circle pid="3" class="st2" cx="61.8" cy="51.1" r="13.5"/><path pid="4" class="st2" d="M48.3 50.6H14.2c-2.5 0-4.5-2-4.5-4.5V24.9c0-2.5 2-4.5 4.5-4.5h43.1c2.5 0 4.5 2 4.5 4.5v12.6"/>`
  }
});
