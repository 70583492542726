/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  document: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M57 73.4H28c-4.7 0-8.5-3.8-8.5-8.5V20.2c0-4.7 3.8-8.5 8.5-8.5h29c4.7 0 8.5 3.8 8.5 8.5v44.7c0 4.6-3.8 8.5-8.5 8.5z"/><path pid="1" class="st1" d="M42.5 36.2c-4.5 0-8.1-3.6-8.1-8.1S38 20 42.5 20s8.1 3.6 8.1 8.1c0 4.4-3.6 8.1-8.1 8.1m0-13.1c-2.7 0-5 2.2-5 5 0 2.7 2.2 5 5 5 2.7 0 5-2.2 5-5 0-2.7-2.3-5-5-5"/><path pid="2" class="st1" d="M55 44.3H30.1v-3.2c0-4.5 3.6-8.1 8.1-8.1h8.7c4.5 0 8.1 3.6 8.1 8.1v3.2zm-21.8-3.1h18.6c0-2.7-2.2-5-5-5h-8.7c-2.7 0-4.9 2.2-4.9 5z"/><path pid="3" class="st0" d="M56.1 51.5H29M56.1 58.3H29M56.1 65H29"/>`
  }
});
