export const AUTH = "auth/AUTH";
export const AUTH_ORIGIN = "auth/AUTH_ORIGIN";
export const AUTH_USER = "auth/AUTH_USER";
export const AUTH_TOKEN = "auth/AUTH_TOKEN";
export const AUTH_LOGIN = "auth/AUTH_LOGIN";
export const AUTH_LOGOUT = "auth/AUTH_LOGOUT";
export const AUTH_CHECK_USER = "auth/AUTH_CHECK_USER";
export const AUTH_OPEN_BEARER = "auth/AUTH_OPEN_BEARER";
export const AUTH_ACTIVE_STORE = "auth/AUTH_ACTIVE_STORE";
export const AUTH_ACTIVE_BRANCH = "auth/AUTH_ACTIVE_BRANCH";
export const AUTH_VERIFY_CAPTCHA = "auth/AUTH_VERIFY_CAPTCHA";
export const AUTH_EMAIL_CONFIRMATION = "auth/AUTH_EMAIL_CONFIRMATION";
export const AUTH_SHOW_EMAIL_FEEDBACK = "auth/AUTH_SHOW_EMAIL_FEEDBACK";
export const AUTH_ACTIVE_STORE_DETAILS = "auth/AUTH_ACTIVE_STORE_DETAILS";
export const AUTH_ACTIVE_BRANCH_CHOICE = "auth/AUTH_ACTIVE_BRANCH_CHOICE";
export const AUTH_ACTIVE_BRANCH_DETAILS = "auth/AUTH_ACTIVE_BRANCH_DETAILS";
export const AUTH_RESET_PASSWORD = "auth/RESET_PASSWORD";
export const AUTH_CAMES_FROM_SIGNUP = "auth/AUTH_CAMES_FROM_SIGNUP";

export const MENU_OPEN = "menu/MENU_OPEN";
export const MENU_ITEMS = "menu/MENU_ITEMS";
export const MENU_CLOSE = "menu/MENU_CLOSE";

export const SALE = "sale/SALE";
export const SALE_RESET = "sale/SALE_RESET";
export const SALE_CANCEL = "sale/SALE_CANCEL";
export const SALE_RECORD = "sale/SALE_RECORD";
export const SALE_SUBMIT = "sale/SALE_SUBMIT";
export const SALE_DETAILS = "sale/SALE_DETAILS";
export const SALE_INSTALLMENTS = "sale/SALE_INSTALLMENTS";
export const SALE_MORE_DETAILS = "sale/SALE_MORE_DETAILS";
export const SALE_NEXT_DUE_DATE = "sale/SALE_NEXT_DUE_DATE";
export const SALE_LAST_SALE = "sale/SALE_LAST_SALE";
export const SALE_RECEIPT_WHATSAPP = "sale/SALE_RECEIPT_WHATSAPP";

export const PAYMENT_ITEMS = "payment/PAYMENT_ITEMS";
export const PAYMENT_RESET = "payment/PAYMENT_RESET";
export const PAYMENT_CANCEL = "payment/PAYMENT_CANCEL";
export const PAYMENT_UPDATE = "payment/PAYMENT_UPDATE";
export const PAYMENT_SUBMIT = "payment/PAYMENT_SUBMIT";
export const PAYMENT_SUBMIT_BILLET = "payment/PAYMENT_SUBMIT_BILLET";
export const PAYMENT_DETAILS = "payment/PAYMENT_DETAILS";
export const PAYMENT_MORE_DETAILS = "payment/PAYMENT_MORE_DETAILS";

export const DEAL_PLANS = "deal/DEAL_PLANS";
export const DEAL_RESET = "deal/DEAL_RESET";
export const DEAL_SUBMIT = "deal/DEAL_SUBMIT";
export const DEAL_DETAILS = "deal/DEAL_DETAILS";
export const DEAL_INSTALLMENTS = "deal/DEAL_INSTALLMENTS";
export const DEAL_MORE_DETAILS = "deal/DEAL_MORE_DETAILS";

export const BILLET_CANCEL = "billets/BILLET_CANCEL";

export const BRANCH_LIST = "branch/BRANCH_LIST";
export const BRANCH_CREATE = "branch/BRANCH_CREATE";
export const BRANCH_UPDATE = "branch/BRANCH_UPDATE";
export const BRANCH_DELETE = "branch/BRANCH_DELETE";
export const BRANCH_DETAILS = "branch/BRANCH_DETAILS";
export const BRANCH_LINK_STAFF = "branch/BRANCH_LINK_STAFF";
export const BRANCH_UNLINK_STAFF = "branch/BRANCH_UNLINK_STAFF";

export const STAFF_ACTIVE = "staff/STAFF_ACTIVE";
export const STAFF_LIST = "staff/STAFF_LIST";
export const STAFF_CREATE = "staff/STAFF_CREATE";
export const STAFF_UPDATE = "staff/STAFF_UPDATE";
export const STAFF_DELETE = "staff/STAFF_DELETE";
export const STAFF_DETAILS = "staff/STAFF_DETAILS";
export const STAFF_SEARCH_BY_CPF = "staff/STAFF_SEARCH_BY_CPF";

export const CLIENT = "client/CLIENT";
export const CLIENT_SET = "client/CLIENT_SET";
export const CLIENT_SEARCH = "client/CLIENT_SEARCH";
export const CLIENT_BY_ID = "client/CLIENT_BY_ID";
export const CLIENT_INVOICES = "client/CLIENT_INVOICES";
export const CLIENT_INVOICE_DETAILS = "client/CLIENT_INVOICE_DETAILS";
export const CLIENT_LIST = "client/CLIENT_LIST";
export const CLIENT_TOKEN = "client/CLIENT_TOKEN";
export const CLIENT_TOKEN_SALE = "client/CLIENT_TOKEN_SALE";
export const CLIENT_RESET = "client/CLIENT_RESET";
export const CLIENT_SHARE = "client/CLIENT_SHARE";
export const CLIENT_CREATE = "client/CLIENT_CREATE";
export const CLIENT_REGISTER = "client/CLIENT_REGISTER";
export const CLIENT_IS_SET = "client/CLIENT_IS_SET";
export const CLIENT_STATUS = "client/CLIENT_STATUS";
export const CLIENT_UPDATE = "client/CLIENT_UPDATE";
export const CLIENT_SELFIE = "client/CLIENT_SELFIE";
export const CLIENT_HISTORY = "client/CLIENT_HISTORY";
export const CLIENT_DETAILS = "client/CLIENT_DETAILS";
export const CLIENT_DOCUMENTS = "client/CLIENT_DOCUMENTS";
export const CLIENT_ACTIVITIES = "client/CLIENT_ACTIVITIES";
export const CLIENT_STORE_DETAILS = "client/CLIENT_STORE_DETAILS";
export const CLIENT_RELATION_ACTIVITIES = "client/CLIENT_RELATION_ACTIVITIES";
export const CLIENT_PRESERVE_SET = "client/CLIENT_PRESERVE_SET";
export const CLIENT_UPDATE_STORE = "client/CLIENT_UPDATE_STORE";
export const CLIENT_SEARCH_BY_CPF = "client/CLIENT_SEARCH_BY_CPF";
export const CLIENT_PUBLIC_DETAILS = "client/CLIENT_PUBLIC_DETAILS";
export const CLIENT_SCORE_NEOPAG = "client/CLIENT_SCORE_NEOPAG";
export const CLIENT_USER_DATA = "client/CLIENT_USER_DATA";
export const CLIENT_PAGINATED_LIST = "client/CLIENT_PAGINATED_LIST";
export const CLIENT_CHANGE_DUE_DAY = "client/CLIENT_CHANGE_DUE_DAY";
export const CLIENT_ACTIVITIES_ANSWERS = "client/CLIENT_ACTIVITIES_ANSWERS";
export const CLIENT_INSTALLMENTS = "client/CLIENT_INSTALLMENTS";
export const CLIENT_UPDATE_INVOICE = "client/CLIENT_UPDATE_INVOICE";
export const CLIENT_CONFIRM_PHONE = "client/CLIENT_CONFIRM_PHONE";
export const CLIENT_LOOSE_BILLS = "client/CLIENT_LOOSE_BILLS";

export const REPORTS = "reports/REPORTS";
export const REPORTS_PAYMENTS = "reports/REPORTS_PAYMENTS";
export const REPORTS_DEALS = "reports/REPORTS_DEALS";
export const REPORTS_STATEMENTS = "reports/REPORTS_STATEMENTS";
export const REPORTS_BILLETS = "reports/REPORTS_BILLETS";
export const REPORTS_SALE_INFO = "reports/REPORTS_SALE_INFO";
export const REPORTS_STATEMENTS_TOTALS = "reports/REPORTS_STATEMENTS_TOTALS";

export const DASHBOARD_INCOME = "dashboard/DASHBOARD_INCOME";
export const DASHBOARD_CHARTS = "dashboard/DASHBOARD_CHARTS";
export const DASHBOARD_DEFINITIONS = "dashboard/DASHBOARD_DEFINITIONS";
export const DASHBOARD_SALE_HISTORY = "dashboard/DASHBOARD_SALE_HISTORY";
export const DASHBOARD_CLIENT_WALLET = "dashboard/DASHBOARD_CLIENT_WALLET";
export const DASHBOARD_CREDIT_WALLET = "dashboard/DASHBOARD_CREDIT_WALLET";

export const SUITABILITY_UNDO = "suitability/SUITABILITY_UNDO";
export const SUITABILITY_RESET = "suitability/SUITABILITY_RESET";
export const SUITABILITY_ANSWER = "suitability/SUITABILITY_ANSWER";
export const SUITABILITY_ANSWERS = "suitability/SUITABILITY_ANSWERS";
export const SUITABILITY_QUESTIONS = "suitability/SUITABILITY_QUESTIONS";
export const SUITABILITY_ANSWERS_ALT = "suitability/SUITABILITY_ANSWERS_ALT";
export const SUITABILITY_ANSWERS_TIME = "suitability/SUITABILITY_ANSWERS_TIME";

export const CHARGE_MAT = "charge/CHARGE_MAT";
export const CHARGE_WALLET = "charge/CHARGE_WALLET";
export const CHARGE_HISTORY = "charge/CHARGE_HISTORY";
export const CHARGE_DEFINITIONS = "charge/CHARGE_DEFINITIONS";
export const CHARGE_LIST = "charge/CHARGE_LIST";
export const CHARGE_REPORTS = "charge/CHARGE_REPORTS";

export const STORE_FAQ = "store/STORE_FAQ";
export const STORE_INFO = "store/STORE_INFO";
export const STORE_USERS = "store/STORE_USERS";
export const STORE_PLANS = "store/STORE_PLANS";
export const STORE_BANKS = "store/STORE_BANKS";
export const STORE_CONFIG = "store/STORE_CONFIG";
export const STORE_SEND_SMS = "store/STORE_SEND_SMS";
export const STORE_SEND_SMS_SALE = "store/STORE_SEND_SMS_SALE";
export const STORE_DEAL_PLANS = "store/STORE_DEAL_PLANS";
export const STORE_IGNORE_LIST = "store/STORE_IGNORE_LIST";
export const STORE_INFO_UPDATE = "store/STORE_INFO_UPDATE";
export const STORE_LOGO_UPDATE = "store/STORE_LOGO_UPDATE";
export const STORE_CARD_UPDATE = "store/STORE_CARD_UPDATE";
export const STORE_UPLOAD_CARD = "store/STORE_UPLOAD_CARD";
export const STORE_BANK_ACCOUNT = "store/STORE_BANK_ACCOUNT";
export const STORE_PLANS_CREATE = "store/STORE_PLANS_CREATE";
export const STORE_PLANS_UPDATE = "store/STORE_PLANS_UPDATE";
export const STORE_PLANS_DELETE = "store/STORE_PLANS_DELETE";
export const STORE_CONFIG_UPDATE = "store/STORE_CONFIG_UPDATE";
export const STORE_DEAL_PLANS_UPDATE = "store/STORE_DEAL_PLANS_UPDATE";
export const STORE_DEAL_PLANS_CREATE = "store/STORE_DEAL_PLANS_CREATE";
export const STORE_DEAL_PLANS_DELETE = "store/STORE_DEAL_PLANS_DELETE";
export const STORE_POTENTIAL_CUSTOMERS = "store/STORE_POTENTIAL_CUSTOMERS";
export const STORE_CUSTOMERS_TO_APPROVE = "store/STORE_CUSTOMERS_TO_APPROVE";
export const STORE_BANK_ACCOUNT_DETAILS = "store/STORE_BANK_ACCOUNT_DETAILS";
export const STORE_WITHDRAWAL_LIST = "store/STORE_WITHDRAWAL_LIST";
export const STORE_WITHDRAWALS = "store/STORE_WITHDRAWALS";
export const STORE_BILLETS_BALANCES = "store/STORE_BILLETS_BALANCES";
export const STORE_GET_BILLETS_BALANCES = "store/STORE_GET_BILLETS_BALANCES";
export const STORE_REQUEST_WITHDRAWAL = "store/STORE_REQUEST_WITHDRAWAL";
export const STORE_BANK_DOMICILE_UPLOAD = "store/STORE_BANK_DOMICILE_UPLOAD";
export const STORE_CHARGING_TIMELINE = "store/STORE_CHARGING_TIMELINE";
export const STORE_TOGGLE_BILLETS = "store/STORE_TOGGLE_BILLETS";
export const STORE_GETTING_STARTED = "store/STORE_GETTING_STARTED";
export const STORE_ACTIVITIES_TIMELINE = "store/STORE_ACTIVITIES_TIMELINE";
export const STORE_IGNORE_USER = "store/IGNORE_USER";
export const STORE_INVOICE_STATUS = "store/INVOICE_STATUS";
export const STORE_TRIAL_STATUS = "store/TRIAL_STATUS";
export const STORE_STAFF_PERMISSIONS = "store/STAFF_PERMISSIONS";
export const STORE_UPDATE_STAFF_PERMISSIONS = "store/UPDATE_STAFF_PERMISSIONS";
export const STORE_CUSTOMERS_STATUS = "store/STORE_CUSTOMERS_STATUS";
export const STORE_CURRENT_QUERIES = "store/CURRENT_QUERIES";
export const STORE_CREATE = "store/STORE_CREATE";
export const STORE_ALREADY_CREATED = "store/STORE_ALREADY_CREATED";
export const STORE_CATEGORIES = "store/STORE_CATEGORIES";
export const STORE_CASH_FLOW = "store/STORE_CASH_FLOW";
export const STORE_SCORE_LIMIT = "store/STORE_SCORE_LIMIT";
export const UPDATE_STORE_SCORE_LIMIT = "store/UPDATE_STORE_SCORE_LIMIT";
export const STORE_CREDIT_LIMITS = "store/STORE_CREDIT_LIMITS";
export const UPDATE_STORE_CREDIT_LIMITS = "store/UPDATE_STORE_CREDIT_LIMITS";

export const SUBSCRIPTION_PLANS = "subscription/SUBSCRIPTION_PLANS";
export const SUBSCRIPTION_UPDATE = "subscription/SUBSCRIPTION_UPDATE";
export const SUBSCRIPTION_CREATE = "subscription/SUBSCRIPTION_CREATE";
export const SUBSCRIPTION_CONFIG = "subscription/SUBSCRIPTION_CONFIG";
export const SUBSCRIPTION_DETAILS = "subscription/SUBSCRIPTION_DETAILS";
export const SUBSCRIPTION_PAYMENT_METHODS =
  "subscription/SUBSCRIPTION_PAYMENT_METHODS";
export const SUBSCRIPTION_PAYMENT_METHODS_CREATE =
  "subscription/SUBSCRIPTION_PAYMENT_METHODS_CREATE";
export const SUBSCRIPTION_PAYMENT_METHODS_UPDATE =
  "subscription/SUBSCRIPTION_PAYMENT_METHODS_UPDATE";
export const GET_SUBSCRIPTION_PAYMENT_METHODS =
  "subscription/GET_SUBSCRIPTION_PAYMENT_METHODS";

export const REMITTANCE_LIST = "remittance/REMITTANCE_LIST";
export const REMITTANCE_CREATE = "remittance/REMITTANCE_CREATE";
export const REMITTANCE_UPLOAD = "remittance/REMITTANCE_UPLOAD";
export const REMITTANCE_RETURNS = "remittance/REMITTANCE_RETURNS";
export const REMITTANCE_BILLETS = "remittance/REMITTANCE_BILLETS";
export const REMITTANCE_REDO_BILLETS = "remittance/REMITTANCE_REDO_BILLETS";
export const REMITTANCE_PENDING_BILLETS =
  "remittance/REMITTANCE_PENDING_BILLETS";
export const REMITTANCE_PENDING_PAYMENT_BILLETS =
  "remittance/REMITTANCE_PENDING_PAYMENT_BILLETS";
export const REMITTANCE_PENDING_REGISTRATION_BILLETS =
  "remittance/REMITTANCE_PENDING_REGISTRATION_BILLETS";

export const REPAY_SALE = "repay/REPAY_SALE";

export const USER_INFO_BY_CPF = "user/USER_INFO_BY_CPF";
export const USER_INFO_BY_EMAIL = "user/USER_INFO_BY_EMAIL";
export const USER_INFO_BY_EMAIL_RESET = "user/USER_INFO_BY_EMAIL_RESET";
export const USER_CREATE_PASSWORD = "user/USER_CREATE_PASSWORD";
export const USER_CREATE = "user/USER_CREATE";
export const USER_CONFIRM_PHONE = "user/USER_CONFIRM_PHONE";
export const USER_PHONE_TOKEN = "user/USER_PHONE_TOKEN";
export const USER_CREDIT_HISTORY = "client/USER_CREDIT_HISTORY";

export const GET_LAT_LNG = "location/GET_LAT_LNG";
export const LAT_LNG = "location/LAT_LNG";
export const RESET_LAT_LNG = "location/RESET_LAT_LNG";
