/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  camera: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M42.6 37.6c5 0 9.1 4.1 9.1 9.1s-4.1 9.1-9.1 9.1-9.1-4.1-9.1-9.1 4.1-9.1 9.1-9.1m0-3.2c-6.8 0-12.3 5.5-12.3 12.3 0 6.8 5.5 12.3 12.3 12.3 6.8 0 12.3-5.5 12.3-12.3 0-6.8-5.5-12.3-12.3-12.3"/><path pid="1" class="st1" d="M55.4 30.6h6.4V37h-6.4z"/><path pid="2" class="st2" d="M57.1 26.5c0-4.7-3.8-8.5-8.5-8.4H36.5c-4.7-.1-8.5 3.7-8.5 8.4h-6.9c-4.7 0-8.5 3.8-8.5 8.5v23.5c0 4.7 3.8 8.5 8.5 8.5h43c4.7 0 8.5-3.8 8.5-8.5V35c0-4.7-3.8-8.5-8.5-8.5h-7z"/>`
  }
});
