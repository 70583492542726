const sizeTable = {
  H1: 100,
  H2: 95,
  H3: 90,
  H4: 85,
  H5: 80,
  H6: 80,
  P: 90,
  SPAN: 90
};

function toggleClass(el, toggle) {
  const size = sizeTable[el.tagName] || 90;
  el.classList.toggle("neopag-loader", toggle);
  el.classList.toggle(`neopag-loader--${size}`, toggle);
}

export default {
  name: "loader",
  inserted(el, binding) {
    toggleClass(el, binding.value);
  },
  componentUpdated(el, binding) {
    toggleClass(el, binding.value);
  }
};
