/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  logout: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M67.8 42.5l-10 10-2.3-2.2 7.8-7.8-7.8-7.8 2.3-2.2z"/><path pid="1" class="st0" d="M45.2 41h19.3v3.1H45.2z"/><path pid="2" class="st1" d="M52.8 58.9V64c0 4.7-3.8 8.5-8.5 8.5H25.8c-4.7 0-8.5-3.8-8.5-8.5V21c0-4.7 3.8-8.5 8.5-8.5h18.5c4.7 0 8.5 3.8 8.5 8.5v4.9"/>`
  }
});
