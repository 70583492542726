import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import uniqBy from "lodash/uniqBy";
import errorHandler from "@/helpers/errorHandler";

// const shuffleArray = (array) => {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];
//   }
// }

const state = {
  config: {
    overdue: {
      interest: 0,
      days: 0
    },
    cutoff_days: 0,
    due_days: []
  },
  info: {
    id: "",
    phone: "",
    name: "",
    logo: null,
    location: {
      longitude: "",
      latitude: ""
    },
    cnpj: "",
    address: {
      uf: "",
      street: "",
      number: "",
      longitude: "",
      latitude: "",
      complement: "",
      city: "",
      cep: ""
    },
    create_billets: false
  },
  plans: [],
  deal_plans: [],
  customers_to_approve: [],
  potential_customers: [],
  ignore_list: [],
  faq: [],
  users: {
    active: -1,
    rejected: 0
  },
  bankAccount: {},
  banks: [],
  withdrawals: [],
  billets_balances: {},
  timeline: [],
  activities: [],
  // permissions: {},
  categories: [],
  storeCreated: {},
  storeCashFlow: [],
  score_limit: {},
  credit_limits: {}
};

const getters = {
  [types.STORE_FAQ]: "faq",
  [types.STORE_INFO]: "info",
  [types.STORE_USERS]: "users",
  [types.STORE_BANK_ACCOUNT]: "bankAccount",
  [types.STORE_WITHDRAWALS]: "withdrawals",
  [types.STORE_BILLETS_BALANCES]: "billets_balances",
  [types.STORE_BANKS]: "banks",
  [types.STORE_PLANS]: "plans",
  [types.STORE_CONFIG]: "config",
  [types.STORE_DEAL_PLANS]: "deal_plans",
  [types.STORE_CUSTOMERS_TO_APPROVE]: "customers_to_approve",
  [types.STORE_POTENTIAL_CUSTOMERS]: state => {
    return uniqBy(state.potential_customers, "id").filter(
      it => state.ignore_list.indexOf(it.id) === -1
    );
  },
  [types.STORE_IGNORE_LIST]: "ignore_list",
  [types.STORE_CHARGING_TIMELINE]: "timeline",
  [types.STORE_ACTIVITIES_TIMELINE]: "activities",
  // [types.STORE_STAFF_PERMISSIONS]: "permissions",
  [types.STORE_CATEGORIES]: "categories",
  [types.STORE_ALREADY_CREATED]: "storeCreated",
  [types.STORE_CASH_FLOW]: "storeCashFlow",
  [types.STORE_SCORE_LIMIT]: "score_limit",
  [types.STORE_CREDIT_LIMITS]: "credit_limits"
};

const mutations = {
  [types.STORE_FAQ]: "faq",
  [types.STORE_INFO]: "info",
  [types.STORE_USERS]: "users",
  [types.STORE_BANKS]: "banks",
  [types.STORE_PLANS]: "plans",
  [types.STORE_BANK_ACCOUNT]: "bankAccount",
  [types.STORE_WITHDRAWALS]: "withdrawals",
  [types.STORE_BILLETS_BALANCES]: "billets_balances",
  [types.STORE_CONFIG]: "config",
  [types.STORE_DEAL_PLANS]: "deal_plans",
  [types.STORE_CUSTOMERS_TO_APPROVE]: "customers_to_approve",
  [types.STORE_POTENTIAL_CUSTOMERS]: (state, payload) => {
    state.potential_customers = uniqBy(payload, "id").filter(
      it => state.ignore_list.indexOf(it.id) === -1
    );
    // shuffleArray(state.potential_customers);
  },
  [types.STORE_IGNORE_LIST]: (state, payload) => {
    state.ignore_list.push(payload.id);
  },
  [types.STORE_TOGGLE_BILLETS]: "toggle_billets",
  [types.STORE_CHARGING_TIMELINE]: "timeline",
  [types.STORE_ACTIVITIES_TIMELINE]: "activities",
  // [types.STORE_STAFF_PERMISSIONS]: "permissions",
  [types.STORE_CATEGORIES]: "categories",
  [types.STORE_ALREADY_CREATED]: "storeCreated",
  [types.STORE_CASH_FLOW]: "storeCashFlow",
  [types.STORE_SCORE_LIMIT]: "score_limit",
  [types.STORE_CREDIT_LIMITS]: "credit_limits"
};

const actions = {
  [types.STORE_CURRENT_QUERIES]: async (_, store_id) => {
    try {
      const response = await axios.get(
        `/stores/${store_id}/get_current_queries`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CUSTOMERS_STATUS]: async () => {
    try {
      const response = await axios.get("/store_customer/status");
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_STAFF_PERMISSIONS]: async (_, { storeId, staffId }) => {
    try {
      const response = await axios.get(
        `/stores/${storeId}/staffs/${staffId}/get_permissions`
      );
      // commit(types.STORE_STAFF_PERMISSIONS, response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_UPDATE_STAFF_PERMISSIONS]: async (
    _,
    { storeId, staffId, permissions }
  ) => {
    try {
      const response = await axios.put(
        `/stores/${storeId}/staffs/${staffId}/update_permissions`,
        permissions
      );
      // commit(types.STORE_STAFF_PERMISSIONS, response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_TRIAL_STATUS]: async (_, storeId) => {
    try {
      const resp = await axios.get(`/stores/${storeId}/trial_status`);
      return Promise.resolve(resp.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_INVOICE_STATUS]: async (_, storeId) => {
    try {
      const resp = await axios.get(`/stores/${storeId}/late_invoice`);
      return Promise.resolve(resp.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_IGNORE_USER]: async (_, { user_id }) => {
    try {
      await axios.put(`store_customers/${user_id}`, {
        store_customer: {
          status_id: 12
        }
      });
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_ACTIVITIES_TIMELINE]: async (
    { commit },
    { storeID, page = 1, page_size = 10 }
  ) => {
    try {
      const response = await axios.get(`/stores/${storeID}/timeline`, {
        params: {
          page,
          page_size
        }
      });
      commit(types.STORE_ACTIVITIES_TIMELINE, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_GETTING_STARTED]: async (_, storeID) => {
    try {
      const response = await axios.get(
        `/stores/${storeID}/getting_started_stats`
      );
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CONFIG]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(`/stores/${storeID}/configs`);
      commit(types.STORE_CONFIG, response.data.data);
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_BANKS]: async ({ commit }) => {
    try {
      const response = await axios.get("/banking/banks");
      commit(types.STORE_BANKS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_REQUEST_WITHDRAWAL]: async (store, payload) => {
    try {
      const resp = await axios.post(
        "/stores/billets/withdrawal_request",
        payload
      );
      return Promise.resolve(resp);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_GET_BILLETS_BALANCES]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(
        `/stores/${storeID}/billets/get_balances`
      );
      commit(types.STORE_BILLETS_BALANCES, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_WITHDRAWAL_LIST]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(
        `/stores/billets/withdrawal_request?store_id=${storeID}`
      );
      commit(types.STORE_WITHDRAWALS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_BANK_ACCOUNT]: async ({dispatch}, payload) => {
    try {
      const response = await axios.post("/banking/bank_accounts", payload);
      await dispatch(types.STORE_BANK_ACCOUNT_DETAILS, payload.store_id);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_BANK_ACCOUNT_DETAILS]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(`/stores/${storeID}/bank_accounts`);
      commit(types.STORE_BANK_ACCOUNT, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_SEND_SMS]: async (
    ctx,
    { userID, phone = false, bearer = false }
  ) => {
    try {
      const params = {};
      if (phone) {
        params.phone_number = phone.replace(/\D/g, "");
      }
      if (bearer) {
        params.headers = {
          Authorization: bearer
        };
      }
      const response = await axios.get(
        `/accounts/users/${userID}/token/v2`,
        params
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_SEND_SMS_SALE]: async (
    ctx,
    { userID, phone = false, bearer = false }
  ) => {
    try {
      const params = {};
      if (phone) {
        params.phone_number = phone.replace(/\D/g, "");
      }
      if (bearer) {
        params.headers = {
          Authorization: bearer
        };
      }
      const response = await axios.get(
        `/accounts/users/${userID}/token`,
        params
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CONFIG_UPDATE]: async ({ dispatch }, { storeID, payload }) => {
    try {
      await axios.put(`/stores/${storeID}/configs`, payload);
      await dispatch(types.STORE_CONFIG, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_INFO]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(`/stores/${storeID}`);
      const {
        // eslint-disable-next-line
        rejected_users_count,
        // eslint-disable-next-line
        active_users_count
      } = response.data.data;
      commit(types.STORE_INFO, response.data.data);
      commit(types.STORE_USERS, {
        active: active_users_count,
        rejected: rejected_users_count
      });
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_INFO_UPDATE]: async ({ dispatch }, { storeID, payload }) => {
    try {
      await axios.put(`/stores/${storeID}`, payload);
      await dispatch(types.STORE_INFO, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_LOGO_UPDATE]: async ({ dispatch }, { storeID, payload }) => {
    try {
      const response = await axios.post(`/stores/${storeID}/logo`, payload);
      await dispatch(types.STORE_INFO, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CARD_UPDATE]: async ({ dispatch }, { storeID, payload }) => {
    try {
      const response = await axios.post(`/stores/${storeID}/card`, payload);
      await dispatch(types.STORE_INFO, storeID);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_PLANS]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(`/stores/${storeID}/plans`);
      commit(types.STORE_PLANS, response.data.data);
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_PLANS_UPDATE]: async (
    { dispatch },
    { storeID, planID, payload }
  ) => {
    try {
      await axios.put(`/stores/${storeID}/plans/${planID}`, payload);
      await dispatch(types.STORE_PLANS, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_PLANS_CREATE]: async ({ dispatch }, { storeID, payload }) => {
    try {
      await axios.post(`/stores/${storeID}/plans`, payload);
      await dispatch(types.STORE_PLANS, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_PLANS_DELETE]: async ({ dispatch }, { storeID, planID }) => {
    try {
      await axios.delete(`/stores/${storeID}/plans/${planID}`);
      await dispatch(types.STORE_PLANS, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_DEAL_PLANS]: async ({ commit }, storeID) => {
    try {
      const response = await axios.get(`/stores/${storeID}/deal_plans`);
      commit(types.STORE_DEAL_PLANS, response.data.data);
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_DEAL_PLANS_UPDATE]: async (
    { dispatch },
    { storeID, planID, payload }
  ) => {
    try {
      await axios.put(`/stores/${storeID}/plans/${planID}`, payload);
      await dispatch(types.STORE_DEAL_PLANS, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_DEAL_PLANS_CREATE]: async (
    { dispatch },
    { storeID, payload }
  ) => {
    try {
      payload.plan.type = "deal";
      await axios.post(`/stores/${storeID}/plans`, payload);
      await dispatch(types.STORE_DEAL_PLANS, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_DEAL_PLANS_DELETE]: async (
    { dispatch },
    { storeID, planID }
  ) => {
    try {
      await axios.delete(`/stores/${storeID}/plans/${planID}`);
      await dispatch(types.STORE_DEAL_PLANS, storeID);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_POTENTIAL_CUSTOMERS]: async ({ commit }, { storeID }) => {
    try {
      const response = await axios.get(
        `/stores/${storeID}/nearby_potential_customers?radius=50`
      );
      commit(types.STORE_POTENTIAL_CUSTOMERS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CUSTOMERS_TO_APPROVE]: async ({ commit }, { storeID }) => {
    try {
      const response = await axios.get(
        `/stores/${storeID}/customers_to_approve`
      );
      commit(types.STORE_CUSTOMERS_TO_APPROVE, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CHARGING_TIMELINE]: async (
    { commit },
    { storeID, page = 1, page_size = 10 }
  ) => {
    try {
      const response = await axios.get(
        `/stores/${storeID}/charging/events/timeline`,
        {
          params: {
            page,
            page_size
          }
        }
      );
      commit(types.STORE_CHARGING_TIMELINE, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_FAQ]: async ({ commit }) => {
    try {
      const response = await axios.get(`/faq?category=store`);
      commit(types.STORE_FAQ, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_BANK_DOMICILE_UPLOAD]: async (_, { bankAccountId, payload }) => {
    try {
      const response = await axios.post(
        `/banking/bank_accounts/${bankAccountId}/upload_domicile`,
        payload
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_TOGGLE_BILLETS]: async (_, { storeID, payload }) => {
    try {
      const response = await axios.put(
        `/stores/${storeID}/toggle_billets`,
        payload
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CREATE]: async ({ commit }, { payload }) => {
    try {
      const response = await axios.post(`stores/bootstrap`, payload);
      commit(types.STORE_ALREADY_CREATED, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CATEGORIES]: async ({ commit }) => {
    try {
      const response = await axios.get(`stores/categories`);
      commit(types.STORE_CATEGORIES, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.STORE_CASH_FLOW]: async ({ commit }, { storeID }) => {
    try {
      const response = await axios.get(`stores/${storeID}/cash_flow
      `);
      commit(types.STORE_CASH_FLOW, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
      [types.STORE_SCORE_LIMIT]: async ({ commit }, storeID) => {
        try {
          const response = await axios.get(`stores/${storeID}/score-limit`);
          commit(types.STORE_SCORE_LIMIT, response.data.score_limit);
          return Promise.resolve(response.data.score_limit);
        } catch (error) {
          return Promise.reject(errorHandler(error.response));
        }
      },
      [types.UPDATE_STORE_SCORE_LIMIT]: async (_, { storeID, payload }) => {
        try {
          const response = await axios.put(`stores/${storeID}/score-limit`, payload);
          return Promise.resolve(response);
        } catch (error) {
          return Promise.reject(errorHandler(error.response));
        }
      },
      [types.STORE_CREDIT_LIMITS]: async ({ commit }, storeID) => {
        try {
          const response = await axios.get(`stores/${storeID}/credit-limits`);
          console.log(response.data.credit_limits)
          await commit(types.STORE_CREDIT_LIMITS, response.data.credit_limits);
          return Promise.resolve(response.data.credit_limits);
        } catch (error) {
          return Promise.reject(errorHandler(error.response));
        }
      },
      [types.UPDATE_STORE_CREDIT_LIMITS]: async (_, { storeID, payload }) => {
        try {
          const response = await axios.put(`stores/${storeID}/credit-limits`, payload);
          return Promise.resolve(response);
        } catch (error) {
          return Promise.reject(errorHandler(error.response));
        }
      },
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
