import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  repaySale: {}
};

const getters = {
  [types.REPAY_SALE]: "repaySale"
};

const mutations = {
  [types.REPAY_SALE]: "repaySale"
};

const actions = {
  [types.REPAY_SALE]: async ({ commit }, { saleID, payload }) => {
    try {
      const response = await axios.post(`/sales/${saleID}/duplicate`, payload);
      commit(types.REPAY_SALE, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
