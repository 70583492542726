import * as types from "@/store/types";
import axios from "axios";
import errorHandler from "@/helpers/errorHandler";
import shortly from "vuex-shortly";

const state = {
  userByCpf: {},
  userByEmail: {},
  userCreatePasswordResponse: {},
  userCreated: {},
  userCreditHistory: []
};

const getters = {
  [types.USER_INFO_BY_CPF]: "userByCpf",
  [types.USER_INFO_BY_EMAIL]: "userByEmail",
  [types.USER_CREATE_PASSWORD]: "userCreatePasswordResponse",
  [types.USER_CREATE]: "userCreated",
  [types.USER_CREDIT_HISTORY]: "userCreditHistory"
};

const mutations = {
  [types.USER_INFO_BY_CPF]: "userByCpf",
  [types.USER_INFO_BY_EMAIL]: "userByEmail",
  [types.USER_CREATE_PASSWORD]: "userCreatePasswordResponse",
  [types.USER_CREATE]: "userCreated",
  [types.USER_CREDIT_HISTORY]: "userCreditHistory"
};

const actions = {
  [types.USER_INFO_BY_CPF]: async ({ commit }, { cpf }) => {
    try {
      if (!cpf.length) return false;
      const response = await axios.get(
        `/accounts/get_status_by_cpf?cpf=${cpf}`
      );
      commit(types.USER_INFO_BY_CPF, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.USER_INFO_BY_EMAIL]: async ({ commit }, { email }) => {
    try {
      const response = await axios.get(
        `/accounts/get_status_by?email=${email}`
      );
      commit(types.USER_INFO_BY_EMAIL, response.data);
      return Promise.resolve(response);
    } catch (error) {}
  },
  [types.USER_INFO_BY_EMAIL_RESET]: ({ commit }) => {
    commit(types.USER_INFO_BY_EMAIL, false);
  },
  [types.USER_CREATE_PASSWORD]: async ({ commit }, { userID, payload }) => {
    try {
      const response = await axios.post(
        `accounts/users/${userID}/create_password`,
        payload
      );
      commit(types.USER_CREATE_PASSWORD, response.data.data);
      const { token, user } = response.data.data;
      commit(types.AUTH_TOKEN, token);
      commit(types.AUTH_USER, user);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.USER_CREATE]: async ({ commit }, { userID, payload }) => {
    try {
      const response = await axios.put(`/accounts/users/${userID}`, payload);
      commit(types.USER_CREATE, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.USER_CONFIRM_PHONE]: async (_, { phone, userID }) => {
    try {
      await axios.post(
        `accounts/users/${userID}/confirm_phone_number/v2?phone_number=${phone}`,
        null
      );
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.USER_PHONE_TOKEN]: async (ctx, { userID, tokenCode }) => {
    try {
      await axios.get(
        `accounts/users/${userID}/check_token?token=${tokenCode}`
      );
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.USER_CREDIT_HISTORY]: async ({ commit }, { userID }) => {
    try {
      const response = await axios.get(
        `accounts/users/${userID}/credit_history`
      );
      commit(types.USER_CREDIT_HISTORY, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
};
export default shortly({
  state,
  getters,
  mutations,
  actions
});
