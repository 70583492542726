/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  plus: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M42.3 26.6v31.9M58.5 42.7H26.6"/>`
  }
});
