/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  map: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M42.5 69.9S23.9 51.8 23.9 33.7c0-10.3 8.3-18.6 18.6-18.6s18.6 8.3 18.6 18.6c0 18.1-18.6 36.2-18.6 36.2z"/><circle pid="1" class="st1" cx="42.5" cy="34.8" r="10"/>`
  }
});
