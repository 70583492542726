/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "file-cross": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M28.062 34.401h29.266v3.15H28.062zM28.062 45.903h29.266v3.15H28.062zM28.062 57.404h13.266v3.15H28.062z"/><path pid="1" fill="#69737D" d="M57.316 74.564H28.074c-5.558 0-10.079-4.521-10.079-10.079V21.243c0-5.558 4.521-10.079 10.079-10.079h24.03L67.396 26.81v37.676c0 5.557-4.522 10.078-10.08 10.078zm-29.242-60.25a6.937 6.937 0 0 0-6.929 6.929v43.242a6.937 6.937 0 0 0 6.929 6.929h29.242a6.937 6.937 0 0 0 6.929-6.929V28.093L50.778 14.314H28.074z"/><path pid="2" fill="#FF5E44" d="M45.675 51.024l13.612 13.613-2.226 2.226-13.613-13.612z"/><g><path pid="3" fill="#FF5E44" d="M56.883 51.025l2.227 2.227-13.612 13.611-2.227-2.226z"/></g><g><path pid="4" fill="#69737D" d="M65.774 30.439h-16.45V12.614h3.151v14.675h13.299z"/></g>`
  }
});
