const isDev =
  process.env.NODE_ENV === "development" ||
  process.env.VUE_APP_MODE === "staging";

import "@/helpers/fillPolyfill.js";
import { version } from "@/../package.json";
import moment from "moment";
import axios from "axios";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import VueTheMask from "vue-the-mask";
import locale from "element-ui/lib/locale/lang/pt-br";
import * as SVGIcon from "vue-svgicon";
import store from "@/store";
// import socket from "@/helpers/socket";
import scroll from "@/helpers/scroll";
import fittyDirective from "@/helpers/fittyDirective";
import loaderDirective from "@/helpers/loaderDirective";
import Egg from "@/helpers/egg";
// import VueRaven from 'vue-raven';
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import VueCookie from "vue-cookie";
import Meta from "vue-meta";
import vueSmoothScroll from "vue-smooth-scroll";
import VueSkeletonLoading from "vue-skeleton-loading";
import * as VueGoogleMaps from "vue2-google-maps";

import {
  tz,
  money,
  avatar,
  captcha,
  breakpoints,
  subscriptionStatus
} from "@/helpers/definitions";

import { AUTH_TOKEN, AUTH_ACTIVE_STORE } from "@/store/types";

moment.locale("pt-br");
moment.addRealMonth = function addRealMonth(d) {
  var fm = moment(d).add(1, "M");
  var fmEnd = moment(fm).endOf("month");
  return d.date() != fm.date() && fm.isSame(fmEnd.format("YYYY-MM-DD"))
    ? fm.add(1, "d")
    : fm;
};

// try {
//   Iugu.setAccountID(process.env.VUE_APP_IUGU);
// } catch (e) {
//   console.log(e);
// }

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.interceptors.request.use(
  config => {
    const interceptedConfig = config;
    const token = store.getters[AUTH_TOKEN];
    const storeID = store.getters[AUTH_ACTIVE_STORE];
    if (interceptedConfig.params && interceptedConfig.params.customHeader) {
      interceptedConfig.headers.common = {
        Authorization: `${interceptedConfig.params.customHeader}`
      };
    } else {
      interceptedConfig.headers.common = {
        Authorization: `${token}`
      };
    }
    if (storeID) {
      if (interceptedConfig.method === "post" && !interceptedConfig.ignore) {
        interceptedConfig.data.store_id = storeID;
      } else if (interceptedConfig.params && !interceptedConfig.params.ignore) {
        if (typeof interceptedConfig.params !== "string")
          interceptedConfig.params.store_id = storeID;
      }
    }
    if (interceptedConfig.params && interceptedConfig.params.ignore) {
      delete interceptedConfig.params.ignore;
    }
    return interceptedConfig;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (
      error &&
      error.response &&
      401 === error.response.status &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/signup"
    ) {
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export default Vue => {
  Vue.use(VueCookie);
  Vue.use(VueI18n);
  Vue.use(ElementUI, {
    locale
  });
  Vue.use(VueTheMask);
  Vue.use(Meta);
  Vue.use(SVGIcon, {
    tagName: "svgicon"
  });
  Vue.use(vueSmoothScroll);
  Vue.use(VueSkeletonLoading);
  Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyAWyUUL6JVavf3StLVo2bqGX8vBHDizJZE",
      libraries: "places"
    },
    installComponents: true
  });

  Sentry.init({
    dsn: "https://269fbc64e6a54ca39646c3741f404c0f@sentry.io/1221045",
    release: version,
    environment: isDev ? "staging" : "production",
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true })
    ],
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications."
    ]
  });
  if (process.env.NODE_ENV !== "development") {
    var firebaseConfig = {
      apiKey: "AIzaSyDAu8yM6Y2SDkau9SyETl5eEwjhez-qhdw",
      authDomain: "neopag-web.firebaseapp.com",
      databaseURL: "https://neopag-web.firebaseio.com",
      projectId: "neopag-web",
      storageBucket: "",
      messagingSenderId: "436838085663",
      appId: "1:436838085663:web:69a2cd64e00b2b64"
    };
    firebase.initializeApp(firebaseConfig); // eslint-disable-line
  }

  // if (isDev) {
  //   try {
  //     Iugu.setTestMode(true);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  Vue.use(fittyDirective);
  Vue.directive("scroll", scroll);
  Vue.directive("loader", loaderDirective);

  Vue.prototype.$tz = tz;
  Vue.prototype.$egg = Egg;
  Vue.prototype.$money = money;
  Vue.prototype.$moment = moment;
  Vue.prototype.$avatar = avatar;
  Vue.prototype.$captcha = captcha;
  Vue.prototype.$breakpoints = breakpoints;
  Vue.prototype.$subscriptionStatus = subscriptionStatus;
  Vue.prototype.$cadastroURL = process.env.VUE_APP_CADASTRO;
  Vue.prototype.$onboardingURL = process.env.VUE_APP_ONBOARDING;
  Vue.prototype.$isDev = isDev;
  Vue.prototype.$isMobile =
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <=
    768;
    Vue.prototype.$neopagVersion = version;
};
