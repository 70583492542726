/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "money-bill": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#0CF9CD" d="M35.772 46.891h3.09c.428 2.213 1.957 3.432 4.285 3.432 2.041 0 3.346-.992 3.346-2.522 0-1.476-.822-2.381-2.807-2.865l-3.518-.85c-2.609-.626-4.086-2.726-4.086-5.561 0-3.829 2.893-5.616 6.412-5.616 3.998 0 6.297 2.268 6.777 5.588h-3.092c-.396-1.618-1.73-2.581-3.658-2.581-2.354 0-3.348 1.106-3.348 2.609 0 1.532.936 2.323 2.24 2.639l3.406.821c3.148.766 4.764 2.667 4.764 5.871 0 3.205-2.355 5.475-6.496 5.475-4.563-.001-7.004-2.752-7.315-6.44"/><path pid="1" fill="#0CF9CD" d="M41.163 30.197h3.152v3.413h-3.152zM41.163 52.521h3.152v3.414h-3.152z"/><g><path pid="2" fill="#69737D" d="M64.208 61.991l-42.997-.02c-5.557-.003-10.076-4.525-10.074-10.083l.008-18.492c.003-5.556 4.524-10.075 10.079-10.075l42.997.02c5.557.003 10.076 4.525 10.074 10.083l-.008 18.492c-.003 5.556-4.524 10.075-10.079 10.075zM21.227 26.472c-3.822 0-6.931 3.107-6.932 6.927l-.008 18.492a6.936 6.936 0 0 0 6.926 6.931l42.992.02.003 1.575v-1.575a6.937 6.937 0 0 0 6.929-6.927l.008-18.492a6.936 6.936 0 0 0-6.926-6.931l-42.992-.02z"/></g>`
  }
});
