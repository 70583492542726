const masks = {
  8: {
    rgx: /(\d{4})(\d{4})/g,
    tpl: "$1-$2"
  },
  9: {
    rgx: /(\d{5})(\d{4})/g,
    tpl: "$1-$2"
  },
  10: {
    rgx: /(\d{2})(\d{4})(\d{4})/g,
    tpl: "($1) $2-$3"
  },
  11: {
    rgx: /(\d{2})(\d{5})(\d{4})/g,
    tpl: "($1) $2-$3"
  },
  12: {
    rgx: /(\d{2})(\d{2})(\d{4})(\d{4})/g,
    tpl: "+$1 ($2) $3-$4"
  },
  13: {
    rgx: /(\d{2})(\d{2})(\d{5})(\d{4})/g,
    tpl: "+$1 ($2) $3-$4"
  }
};

export const format = (value) => {
  if (!value) return '';

  let phoneNumber = value;
  phoneNumber = phoneNumber.replace(/\D/g, '');
  phoneNumber = phoneNumber.replace(/^(\d{2})(\d)/g, '($1) $2');
  phoneNumber = phoneNumber.replace(/(\d)(\d{4})$/, '$1-$2');
  return phoneNumber;
};

export const unformat = (value) => {
  if (!value) return '';

  let phoneNumber = value;
  phoneNumber = phoneNumber.replace(/\D/g, '');
  phoneNumber = phoneNumber.replace(/^(\d{2})(\d)/g, '$1$2');
  phoneNumber = phoneNumber.replace(/(\d)(\d{4})$/, '$1$2');
  return phoneNumber;
};

export default {
  unformat,
  format,
  masks
};
