/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "coin-check": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#0CF9CD" d="M53.765 32.419L52.652 31.4l-5.925-5.923 2.228-2.227 4.762 4.763 10.262-10.262 2.226 2.227z"/><path pid="1" fill="#69737D" d="M36.071 47.688h3.09c.428 2.213 1.957 3.432 4.285 3.432 2.041 0 3.346-.992 3.346-2.522 0-1.476-.822-2.381-2.807-2.865l-3.518-.85c-2.609-.626-4.086-2.726-4.086-5.561 0-3.829 2.893-5.616 6.412-5.616 3.998 0 6.297 2.268 6.777 5.588h-3.092c-.396-1.618-1.73-2.581-3.658-2.581-2.354 0-3.348 1.106-3.348 2.609 0 1.532.936 2.323 2.24 2.639l3.406.821c3.148.766 4.764 2.667 4.764 5.871 0 3.205-2.355 5.475-6.496 5.475-4.563-.001-7.004-2.752-7.315-6.44"/><path pid="2" fill="#69737D" d="M41.462 30.994h3.152v3.413h-3.152zM41.462 53.318h3.152v3.414h-3.152z"/><g><path pid="3" fill="#69737D" d="M42.978 67.938c-13.275 0-24.075-10.8-24.075-24.075s10.8-24.075 24.075-24.075c.628 0 1.258.024 1.874.072l-.244 3.141a20.913 20.913 0 0 0-1.63-.062c-11.538 0-20.925 9.387-20.925 20.925s9.387 20.925 20.925 20.925 20.925-9.387 20.925-20.925c0-3.024-.631-5.94-1.874-8.669a20.993 20.993 0 0 0-2.119-3.63l2.549-1.852a24.193 24.193 0 0 1 2.437 4.176 23.873 23.873 0 0 1 2.158 9.975c-.001 13.275-10.801 24.074-24.076 24.074z"/></g>`
  }
});
