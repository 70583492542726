/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "cart-empty": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M67.491 51.314H29.809l-4.509-27.9H72l-4.509 27.9zm-35.001-3.15h32.32l3.491-21.6H28.999l3.491 21.6z"/><path pid="1" fill="#69737D" d="M29.595 49.99L25.3 23.414h.01l-1.335-8.26h-9.907v-3.15h12.641l.193 1.531 1.599 9.879H70.15v3.15H29.009l3.695 22.924z"/><path pid="2" fill="#69737D" d="M66.316 62.314H31.107c-3.901 0-7.075-3.174-7.075-7.075s3.174-7.075 7.075-7.075v3.15c-2.164 0-3.925 1.761-3.925 3.925s1.761 3.925 3.925 3.925h35.209v3.15z"/><path pid="3" fill="#FF5E44" d="M34.087 74.377c-2.833 0-5.138-2.305-5.138-5.138s2.305-5.138 5.138-5.138 5.138 2.305 5.138 5.138-2.305 5.138-5.138 5.138zm0-7.125c-1.096 0-1.987.892-1.987 1.987s.892 1.987 1.987 1.987 1.987-.892 1.987-1.987-.891-1.987-1.987-1.987zM60.707 74.377a5.134 5.134 0 0 1-5.101-4.559 5.101 5.101 0 0 1 1.086-3.779 5.105 5.105 0 0 1 3.44-1.905 5.133 5.133 0 0 1 5.684 4.526 5.146 5.146 0 0 1-5.109 5.717zm.009-7.126a1.978 1.978 0 0 0-1.56.75c-.33.415-.479.935-.42 1.462a1.988 1.988 0 0 0 3.949-.447 1.982 1.982 0 0 0-1.969-1.765z"/>`
  }
});
