import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  location: {}
};

const getters = {
  [types.LAT_LNG]: "location"
};

const mutations = {
  [types.LAT_LNG]: "location"
};


const actions = {
[types.GET_LAT_LNG]: async ({ commit }, {cep, uglyCep}) => {
  try {
    const response = await axios.get(
      `get_address_lat_long?address=cep ${cep}`
    );
    commit(types.LAT_LNG, response.data.data);
    return Promise.resolve(response);
  } catch (error) {
    try {
      const response = await axios.get(
        `get_address_lat_long?address=cep ${uglyCep}`
      );
      commit(types.LAT_LNG, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  }
},
[types.RESET_LAT_LNG]: ({commit}) => {
  commit(types.LAT_LNG, null);
}
};

export default shortly({
state,
getters,
mutations,
actions
});
