import messages from "./messages.json";
import numberFormats from "./numberFormats.json";
import dateTimeFormats from "./dateTimeFormats.json";

const config = {
  locale: 'pt-BR',
  messages,
  numberFormats,
  dateTimeFormats
}

export default config;
