/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  list: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M65.6 27.2v37c0 4.7-3.8 8.5-8.5 8.5H27.9c-4.7 0-8.5-3.8-8.5-8.5V21c0-4.7 3.8-8.5 8.5-8.5h23.4l14.3 14.7z"/><path pid="1" class="st0" d="M50.7 12.3v16.3h14.9"/><path pid="2" class="st1" d="M26.3 23.1h6.4v6.4h-6.4zM26.3 33.9h6.4v6.4h-6.4zM26.3 44.7h6.4v6.4h-6.4zM26.3 55.6h6.4V62h-6.4z"/><path pid="3" class="st0" d="M58.8 58.8H35.7M58.8 47.9H35.7M58.8 37.1H35.7M44.8 26.3h-9.1"/>`
  }
});
