/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "warning-circle": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M43.066 73.115c-16.874 0-30.605-13.727-30.605-30.605 0-16.877 13.731-30.607 30.605-30.607 16.88 0 30.608 13.73 30.608 30.607.001 16.879-13.728 30.605-30.608 30.605m0-58.072c-15.145 0-27.467 12.32-27.467 27.467s12.322 27.469 27.467 27.469c15.15 0 27.471-12.322 27.471-27.469s-12.32-27.467-27.471-27.467"/><path pid="1" fill="#FF5E44" d="M40.501 40.797h2.565c.7 0 1.364-.125 1.997-.379a4.885 4.885 0 0 0 1.666-1.096 5.366 5.366 0 0 0 1.116-1.615c.269-.602.403-1.25.403-1.947 0-.699-.135-1.355-.403-1.975a5.08 5.08 0 0 0-1.116-1.617 5.5 5.5 0 0 0-1.666-1.092 5.03 5.03 0 0 0-1.997-.402 5.07 5.07 0 0 0-1.996.402 5.498 5.498 0 0 0-1.663 1.092c-.475.461-.848 1-1.117 1.617a4.892 4.892 0 0 0-.402 1.975H32.8c0-1.457.263-2.807.786-4.041a9.69 9.69 0 0 1 2.21-3.234 9.94 9.94 0 0 1 3.28-2.115c1.234-.49 2.565-.736 3.99-.736a10.454 10.454 0 0 1 7.275 2.899 9.707 9.707 0 0 1 2.211 3.234c.522 1.236.784 2.566.784 3.994 0 1.205-.189 2.346-.569 3.422a9.638 9.638 0 0 1-1.618 2.898 10.192 10.192 0 0 1-2.471 2.188 9.911 9.911 0 0 1-3.09 1.285v2.896h-5.087v-7.653zm-.665 12.219h6.368v6.367h-6.368v-6.367z"/>`
  }
});
