import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
// import toPerson from "@/helpers/toPerson";
// import miniSuitability from "@/content/miniSuitability";
import errorHandler from '@/helpers/errorHandler';

const state = {
  questions: {},
  answers: [],
  time: null
};

const getters = {
  [types.SUITABILITY_ANSWERS_TIME]: "time",
  [types.SUITABILITY_QUESTIONS]: "questions",
  [types.SUITABILITY_ANSWERS]: "answers"
};

const mutations = {
  [types.SUITABILITY_QUESTIONS]: "questions",
  [types.SUITABILITY_RESET]: state => {
    state.answers = [];
  },
  [types.SUITABILITY_ANSWER]: (state, answer) => {
    state.answers.push(answer);
  },
  [types.SUITABILITY_UNDO]: state => {
    state.answers.pop();
  },
  [types.SUITABILITY_ANSWERS_TIME]: "time"
};

const actions = {
  [types.SUITABILITY_QUESTIONS]: async ({ commit }) => {
    const response = await axios.get("/suitability");
    commit(types.SUITABILITY_QUESTIONS, response.data.data);
  },
  [types.SUITABILITY_RESET]: ({ commit }) => {
    commit(types.SUITABILITY_RESET);
  },
  [types.SUITABILITY_UNDO]: ({ commit }) => {
    commit(types.SUITABILITY_UNDO);
  },
  [types.SUITABILITY_ANSWER]: ({ commit }, answer) => {
    commit(types.SUITABILITY_ANSWER, answer);
  },
  [types.SUITABILITY_ANSWERS]: async ({ state, rootState }, { user_id }) => {
    try {
      const answers = [...state.answers];
      const suitability = {
        user_id,
        store_id: rootState.auth.store_id,
        total_answer_time: 0,
        due_day: 10,
        answers: [
          ...answers.map(it => {
            return {
              question_id: it.id,
              answer_time: it.answer_time,
              value: it.option.value
            };
          })
        ]
      };
      if (!suitability.user_id)
        return new Error("Tentativa de suitability sem user_id");
      const response = await axios.post(
        "/suitability",
        {
          suitability
        },
        {
          ignore: true
        }
      );
      return Promise.resolve(response);
    } catch(error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.SUITABILITY_ANSWERS_ALT]: async (
    { state, rootState },
    { user_id, due_day }
  ) => {
    try {
      if (!user_id) return new Error("Tentativa de suitability sem user_id");
      const answers = [...state.answers];
      const suitability = {
        user_id,
        due_day,
        store_id: rootState.auth.store_id,
        total_answer_time: 0,
        answers: [
          ...answers.map(it => {
            return {
              question_id: it.id,
              answer_time: it.answer_time,
              value: it.option.value
            };
          })
        ]
      };
      const response = await axios.post(
        "/suitability",
        {
          suitability
        },
        {
          ignore: true
        }
      );
      return Promise.resolve(response);
    } catch(error) {
      return Promise.reject(errorHandler(error.response));
    }
  },

  [types.SUITABILITY_ANSWERS_TIME]: ({ commit }, time) => {
    commit(types.SUITABILITY_ANSWERS_TIME, time);
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
