/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  search: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M35.899 53.814c-10.38 0-18.825-8.445-18.825-18.825s8.445-18.825 18.825-18.825 18.825 8.445 18.825 18.825-8.445 18.825-18.825 18.825zm0-34.5c-8.644 0-15.675 7.031-15.675 15.675s7.031 15.675 15.675 15.675 15.675-7.031 15.675-15.675-7.031-15.675-15.675-15.675z"/><path pid="1" fill="#FF5E44" d="M45.586 30.227a15.658 15.658 0 0 0-11.967-5.551v-3.15a18.8 18.8 0 0 1 14.371 6.666l-2.404 2.035z"/><path pid="2" fill="#69737D" d="M47.826 46.685l5.13 5.129-2.227 2.228-5.13-5.129z"/><path pid="3" fill="#69737D" d="M64.307 69.971a4.542 4.542 0 0 1-3.235-1.34L49.758 57.317c-.864-.863-1.341-2.012-1.341-3.234s.477-2.371 1.341-3.235a4.543 4.543 0 0 1 3.234-1.34c1.223 0 2.372.476 3.235 1.34L67.54 62.161c.864.863 1.341 2.012 1.341 3.234s-.477 2.371-1.341 3.235a4.536 4.536 0 0 1-3.233 1.341zM52.992 52.657a1.42 1.42 0 0 0-1.425 1.426c0 .381.147.738.417 1.007l11.313 11.314c.539.537 1.478.537 2.017 0 .269-.27.417-.628.417-1.009s-.147-.738-.417-1.007L54.001 53.074a1.424 1.424 0 0 0-1.009-.417z"/>`
  }
});
