/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  upload: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path fill="#0CF9CD" d="M48.9 48.4H52v19.3h-3.1z"/><path fill="#0CF9CD" d="M50.5 45.2l10 10-2.3 2.2-7.7-7.8-7.8 7.8-2.2-2.2z"/><path style="fill:none;stroke:#69737D;stroke-width:3.15;stroke-miterlimit:10;" d="M65.6 27.2v37c0 4.7-3.8 8.5-8.5 8.5H27.9c-4.7 0-8.5-3.8-8.5-8.5V21c0-4.7 3.8-8.5 8.5-8.5h23.4l14.3 14.7z"/><path style="fill:none;stroke:#69737D;stroke-width:3.15;stroke-miterlimit:10;" d="M50.7 12.3v16.3h14.9"/>`
  }
});
