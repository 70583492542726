<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import "@/components/Icons";
export default {
  name: "Store",
};
</script>
<style lang="scss">
@import "~normalize.css/normalize.css";
@import "~intro.js/minified/introjs.min.css";
@import "./styles/style.scss";
@import "./styles/vendor/element-variables.scss";
</style>
