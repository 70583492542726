/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "file-plus": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M28.062 34.664h29.266v3.15H28.062zM28.062 46.165h29.266v3.15H28.062zM28.062 57.667h13.266v3.15H28.062z"/><path pid="1" fill="#69737D" d="M57.316 74.064H28.074c-5.558 0-10.079-4.521-10.079-10.079V20.743c0-5.558 4.521-10.079 10.079-10.079h24.03L67.396 26.31v37.676c0 5.557-4.522 10.078-10.08 10.078zm-29.242-60.25a6.937 6.937 0 0 0-6.929 6.929v43.242a6.937 6.937 0 0 0 6.929 6.929h29.242a6.937 6.937 0 0 0 6.929-6.929V27.593L50.778 13.814H28.074z"/><path pid="2" fill="#0CF9CD" d="M51.069 51.735h3.15v14.82h-3.15z"/><g><path pid="3" fill="#0CF9CD" d="M45.33 57.667h14.82v3.15H45.33z"/></g><g><path pid="4" fill="#69737D" d="M65.774 29.939h-16.45V12.114h3.151v14.675h13.299z"/></g>`
  }
});
