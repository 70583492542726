/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  message: {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#0CF9CD" d="M23.57 27.686h38.25v3.107H23.57zM23.57 36.686h38.25v3.107H23.57zM23.57 45.686h38.25v3.107H23.57z"/><path pid="1" fill="#69737D" d="M30.787 66.495l-6.044-8.68H16.41v-39.15h52.483v39.15H37.117l-6.33 8.68zm-11.226-11.83h6.827l4.458 6.402 4.67-6.402h30.228v-32.85H19.561v32.85z"/>`
  }
});
