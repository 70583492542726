/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  cogs: {
    width: 16,
    height: 16,
    viewBox: "0 0 85 85",
    data: `<path pid="0" class="st0" d="M47.8 62.6H37.3v-7.9c-.9-.4-1.8-.9-2.7-1.5l-6.9 4-5.2-9.2 6.9-4c-.1-.5-.1-1-.1-1.5s0-1 .1-1.5l-6.9-4 5.2-9.1 6.9 4c.8-.6 1.7-1.1 2.7-1.5v-7.9h10.5v7.9c.9.4 1.8.9 2.7 1.5l6.9-4 5.2 9.1-6.9 4c.1.5.1 1 .1 1.5s0 1-.1 1.5l6.9 4-5.2 9.1-6.9-4c-.8.6-1.7 1.1-2.7 1.5v8zm-7.4-3.2h4.2v-7l1.1-.4a9.4 9.4 0 0 0 3.5-2l.8-.8 6.1 3.5 2.1-3.6-6.1-3.5.2-1.1c.1-.7.2-1.4.2-2 0-.7-.1-1.4-.2-2l-.2-1.1 6.1-3.5-2.1-3.6-6.1 3.5-.8-.8c-1-.9-2.2-1.6-3.5-2l-1.1-.4v-7h-4.2v7l-1 .4a9.4 9.4 0 0 0-3.5 2l-.9.8-6.1-3.5-2.1 3.6 6.1 3.5-.2 1.1c-.1.7-.2 1.4-.2 2 0 .7.1 1.4.2 2l.2 1.1-6.1 3.5 2.1 3.6 6.1-3.5.9.8c1 .9 2.2 1.6 3.5 2l1.1.4v7z"/><path pid="1" class="st0" d="M42.5 49.3c-3.8 0-6.8-3.1-6.8-6.8 0-3.8 3.1-6.8 6.8-6.8 3.8 0 6.8 3.1 6.8 6.8 0 3.8-3 6.8-6.8 6.8m0-10.5c-2 0-3.7 1.6-3.7 3.7 0 2 1.7 3.7 3.7 3.7s3.7-1.6 3.7-3.7c0-2-1.7-3.7-3.7-3.7"/>`
  }
});
