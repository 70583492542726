function formatError(data) {
  let error = '';
  for (const key in data) {
    if (typeof data[key] === 'object') {
      return formatError(data[key]);
    } else if (typeof data[key] === 'string') {
      error = data[key];
    }
  }
  return error;
}

function errorHandler(response) {
  let message = '';

  if (response && response.status && response.data && typeof response.data !== 'string') {
    message = formatError(response.data);
  } else {
    message = 'Ops... um erro aconteceu, tente novamente!';
  }
  return Object.assign({}, { ...response }, { message });
}

export default errorHandler;
