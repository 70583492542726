/* eslint-disable */

import icon from "vue-svgicon";
icon.register({
  "change-user": {
    width: 85.04,
    height: 85.04,
    viewBox: "0 0 85.04 85.04",
    data: `<path pid="0" fill="#69737D" d="M53.887 71.929l-5.88-5.775h-5.638V48.89h3.143v14.124h3.776l4.597 4.513 4.585-4.513h15.29V37.379H48.649v-3.14h28.252v31.915H59.757z"/><path pid="1" fill="#FF5E44" d="M59.927 53.87c-3.755 0-6.812-3.055-6.812-6.809s3.057-6.81 6.812-6.81 6.812 3.056 6.812 6.81-3.057 6.809-6.812 6.809m0-10.478c-2.023 0-3.67 1.646-3.67 3.67s1.646 3.67 3.67 3.67 3.671-1.646 3.671-3.67-1.647-3.67-3.671-3.67"/><path pid="2" fill="#FF5E44" d="M70.229 60.401H49.628V57.54a6.816 6.816 0 0 1 6.809-6.809h6.983a6.816 6.816 0 0 1 6.81 6.809v2.861zm-17.452-3.139h14.305a3.678 3.678 0 0 0-3.662-3.391h-6.983a3.676 3.676 0 0 0-3.66 3.391"/><path pid="3" fill="#69737D" d="M32.832 57.37l-6.881-6.877-17.068-.038V13.833h41.335V50.45l-10.502.043-6.884 6.877zM12.025 47.323l15.227.035 5.58 5.574 5.577-5.574 8.671-.033V16.973H12.025v30.35z"/><path pid="4" fill="#0CF9CD" d="M29.754 35.769c-4.461 0-8.089-3.629-8.089-8.092 0-4.462 3.628-8.092 8.089-8.092 4.465 0 8.096 3.63 8.096 8.092 0 4.463-3.631 8.092-8.096 8.092m0-13.044a4.957 4.957 0 0 0-4.95 4.952 4.958 4.958 0 0 0 4.95 4.954 4.964 4.964 0 0 0 4.957-4.954 4.962 4.962 0 0 0-4.957-4.952"/><path pid="5" fill="#0CF9CD" d="M42.196 43.897H17.314v-3.176c0-4.462 3.631-8.091 8.095-8.091h8.695c4.462 0 8.092 3.629 8.092 8.091v3.176zm-21.742-3.138h18.601v-.037a4.958 4.958 0 0 0-4.95-4.953H25.41a4.96 4.96 0 0 0-4.955 4.953v.037z"/><path pid="6" fill="#69737D" d="M61.808 28.406l-.062-4.301c.023-1.893-.676-3.642-1.968-4.967a6.885 6.885 0 0 0-4.874-2.089l.039-3.15c2.691.033 5.21 1.113 7.09 3.04s2.896 4.471 2.863 7.163l.062 4.259-3.15.045z"/><path pid="7" fill="#69737D" d="M63.387 31.488l-6.031-6.029 2.227-2.229 3.804 3.803 3.792-3.791 2.226 2.227z"/>`
  }
});
