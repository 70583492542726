import * as types from "@/store/types";
import axios from "axios";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

const state = {
  moreDetails: undefined,
  details: {
    is_valid: false,
    status: undefined,
    payment: [],
    interest: 0,
    discount: 0
  },
  items: []
};

const getters = {
  [types.PAYMENT_DETAILS]: "details",
  [types.PAYMENT_MORE_DETAILS]: "moreDetails",
  [types.PAYMENT_ITEMS]: "items"
};

const mutations = {
  [types.PAYMENT_DETAILS]: "details",
  [types.PAYMENT_MORE_DETAILS]: "moreDetails",
  [types.PAYMENT_ITEMS]: "items"
};

const actions = {
  [types.PAYMENT_DETAILS]: ({ commit }, details) => {
    commit(types.PAYMENT_DETAILS, details);
  },
  [types.PAYMENT_ITEMS]: async ({ commit }, id) => {
    try {
      const response = await axios.get(`/statements/${id}`);
      commit(types.PAYMENT_ITEMS, response.data.data.installments);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.PAYMENT_UPDATE]: async (store, { id, dd }) => {
    try {
      const response = await axios.put(`/statements/${id}`, {
        due_date: dd
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.PAYMENT_SUBMIT]: async ({ commit, getters }, payload) => {
    try {
      if (getters[types.AUTH_ACTIVE_BRANCH]) {
        payload.payment.store_branch_id = getters[types.AUTH_ACTIVE_BRANCH];
      }
      const response = await axios.post("/payments", payload, {
        ignore: true
      });
      if(response.data.data) {
        commit(types.PAYMENT_MORE_DETAILS, response.data.data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.PAYMENT_SUBMIT_BILLET]: async ({ commit }, params) => {
    try {
      const response = await axios.post(
        "/banking/billets/loose_billet",
        params
      );
      commit(types.PAYMENT_MORE_DETAILS, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.PAYMENT_CANCEL]: async (store, paymentID) => {
    try {
      if (!paymentID) return false;
      const response = await axios.put(`/payments/${paymentID}/cancel`);
      const reports = store.getters[types.REPORTS];
      if (reports.length)
        store.commit(
          types.REPORTS,
          reports.filter(it => it.id !== paymentID)
        );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.PAYMENT_MORE_DETAILS]: async ({ commit }, id) => {
    try {
      const response = await axios.get(`/payments/${id}`);
      commit(types.PAYMENT_MORE_DETAILS, response.data.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(errorHandler(error.response));
    }
  },
  [types.PAYMENT_RESET]: ({ commit }) => {
    commit(types.PAYMENT_DETAILS, {
      is_valid: false,
      status: undefined,
      payment: [],
      interest: 0,
      discount: 0
    });
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
