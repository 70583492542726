import axios from "axios";
import * as types from "@/store/types";
import shortly from "vuex-shortly";
import errorHandler from "@/helpers/errorHandler";

// const generateIncome = (start, end) => {
//   const arr = [];
//   const dt = new Date(start);
//   // eslint-disable-next-line
//   while (dt <= end) {
//     arr.push({
//       date: new Date(dt),
//       value: Math.floor(Math.random() * 80) + 20
//     });
//     dt.setDate(dt.getDate() + 1);
//   }
//   return arr;
// };

// const incomeHistory = generateIncome(new Date("2018-04-01"), new Date());

const state = {
  mat: [],
  history: [],
  wallet: [],
  definitions: {},
  list: {},
  reports: {}
};

const getters = {
  [types.CHARGE_MAT]: "mat",
  [types.CHARGE_WALLET]: "wallet",
  [types.CHARGE_HISTORY]: "history",
  [types.CHARGE_DEFINITIONS]: "definitions",
  [types.CHARGE_LIST]: "list",
  [types.CHARGE_REPORTS]: "reports"
};

const mutations = {
  [types.CHARGE_MAT]: "mat",
  [types.CHARGE_WALLET]: "wallet",
  [types.CHARGE_HISTORY]: "history",
  [types.CHARGE_DEFINITIONS]: "definitions",
  [types.CHARGE_LIST]: "list",
  [types.CHARGE_REPORTS]: "reports"
};

const actions = {
  [types.CHARGE_MAT]: async ({ commit, rootState }) => {
    try {
      const response = await axios.get(
        `stores/${rootState.auth.store_id}/collection/steps`
      );
      const list = response.data.data.sort((a, b) => {
        return a.trigger - b.trigger;
      });
      commit(types.CHARGE_MAT, list);
      return Promise.resolve(response);
    } catch (error) {
      return errorHandler(error.response);
    }
  },
  [types.CHARGE_HISTORY]: async ({ commit, rootState }) => {
    try {
      const response = await axios.get(
        `stores/${rootState.auth.store_id}/payments`
      );
      commit(
        types.CHARGE_HISTORY,
        response.data.data
          .map(it => {
            it.amount = +it.amount;
            it.date = new Date(it.payment_date);
            it.sale_date = it.payment_date;
            return it;
          })
          .sort((a, b) => {
            const A = a.date.getTime();
            const B = b.date.getTime();
            return A - B;
          })
      );
    } catch (error) {
      return errorHandler(error.response);
    }
  },
  [types.CHARGE_DEFINITIONS]: async ({ commit, rootState }) => {
    try {
      const storeID = rootState.auth.store_id;
      const response = await axios.get(`/stores/${storeID}/dashboard_charts`);
      commit(types.CHARGE_DEFINITIONS, response.data.data);
      return response.data;
    } catch (error) {
      return errorHandler(error.response);
    }
  },
  [types.CHARGE_LIST]: async (
    { commit, rootState },
    {
      page = 1,
      series,
      order_by = "kind_asc",
      initial_date,
      end_date,
      q,
      page_size = 10
    }
  ) => {
    try {
      const params = {
        page,
        series,
        order_by,
        initial_date,
        end_date,
        q,
        page_size
      };
      const response = await axios.get(
        `stores/${rootState.auth.store_id}/charging/events/list_logs`,
        { params }
      );
      commit(types.CHARGE_LIST, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return errorHandler(error.response);
    }
  },
  [types.CHARGE_REPORTS]: async (
    { commit, rootState },
    {
      page = 1,
      status,
      order_by = "kind_asc",
      initial_date,
      end_date,
      q,
      page_size
    }
  ) => {
    try {
      //delay
      //ok
      //late
      //lost
      const params = {
        page,
        status,
        order_by,
        initial_date,
        end_date,
        q,
        page_size
      };
      const response = await axios.get(
        `stores/${rootState.auth.store_id}/reports/consolidated_positions`,
        { params }
      );
      commit(types.CHARGE_REPORTS, response.data);
      return Promise.resolve(response);
    } catch (error) {
      return errorHandler(error.response);
    }
  }
};

export default shortly({
  state,
  getters,
  mutations,
  actions
});
